import { useContext, useEffect, useState } from "react";
import {
  Button,
  makeStyles,
  TextField,
  Theme,
  Typography,
  Backdrop,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { login } from "../../Services/AuthService";
import { AxiosError } from "axios";
import history from "../../Services/history";
import GlobalContext from "../../GlobalContext/GlobalContext";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextOfTerm from "../PrivacyTermPage/Components/TextOfTerm";
import DrawerCreateAccount from "./Components/DrawerCreateAccount";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    background: "linear-gradient(to right, #38B86B, #06cb3f, #07d680, #85ffc5)",
  },
  formContainer: {
    width: "min(90vw, 1000px)",
    height: "min(75vh, 600px)",
    margin: "min(12vh, 80px) 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
    boxShadow: "0 1px 3px 0 rgba(166, 175, 195, 0.4)",
    backgroundColor: "#fff",
  },
  containerLogin: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    maxWidth: "800px",
  },
  form: {
    width: "100%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "min(2vh, 16px)",
    padding: "min(2vh, 16px) min(4vw, 32px)",
  },
  img: {
    display: "block",
    margin: "0 auto min(2vh, 16px)",
    height: "min(10vh, 64px)",
    width: "auto",
  },
  textTitle: {
    fontWeight: 600,
    fontFamily: "Open Sans",
    fontSize: "min(2.2vh, 16px)",
    color: "#3E5055",
    textAlign: "left",
  },
  textSubtitle: {
    color: "#8B979F",
    fontSize: "min(1.8vh, 12px)",
    textAlign: "left",
    marginBottom: "min(2vh, 16px)",
  },
  passwordLink: {
    color: "#1890ff",
    cursor: "pointer",
    fontSize: "min(1.8vh, 12px)",
  },
  containerButtons: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: "min(1vh, 8px)",
  },
  bottomContainerLogin: {
    alignItems: "center",
    alignContent: "center",
    marginTop: "min(1vh, 8px)",
  },
  textSubtitleModal: {
    textAlign: "center",
    fontSize: "min(1.5vh, 10px)",
    color: "#8B979F",
    "&:hover": {
      cursor: "pointer",
    },
  },
  formControl: {
    minWidth: "120px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  underline: {
    "&:before": {
      borderBottomColor: "#9ca3af",
    },
    "&:after": {
      borderBottomColor: "#dfe4ea",
    },
  },
  textField: {
    width: "100%",
    height: "min(7vh, 50px)",
    "& .MuiInputLabel-root": {
      fontSize: "min(1.8vh, 14px)",
    },
    "& .MuiInputBase-input": {
      fontSize: "min(1.8vh, 14px)",
    },
  },
  loginButton: {
    width: "100%",
    height: "min(6vh, 40px)",
    fontSize: "min(1.8vh, 14px)",
    textTransform: "capitalize",
  },
  createAccountText: {
    fontSize: "min(1.8vh, 12px)",
    textAlign: "center",
    marginTop: "min(1vh, 8px)",
    color: "#3E5055",
    "& b": {
      fontWeight: 600,
    },
    "& a": {
      textDecoration: "none",
      color: "#1890ff",
    },
  },
}));

const LoginPage: React.FunctionComponent = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [visibilityPassword, setVisibilityPassword] = useState<boolean>(false);
  const [rand, setRand] = useState<number>(0);
  const [showDrawerCreateAccount, setShowDraweCreaterAccount] = useState(false);
  const { isLogged, setIsLogged } = useContext(GlobalContext);

  const maxValue = 3;

  useEffect(() => {
    setRand(Math.floor(Math.random() * maxValue) + 1);

    return () => {
      clearErrors();
      setOpen(false);
    };
  }, []);

  const classes = useStyles();

  const clearErrors = () => {
    setEmailError("");
    setPasswordError("");
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError("");
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordError("");
  };

  const handleCreateAccountClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    setShowDraweCreaterAccount(true);
  };

  const validateFields = (): boolean => {
    let isValid = true;
    clearErrors();

    if (!email.trim()) {
      setEmailError("Por favor, preencha o campo");
      isValid = false;
    }

    if (!password.trim()) {
      setPasswordError("Por favor, preencha o campo");
      isValid = false;
    }

    return isValid;
  };

  const sendLogin = () => {
    if (!validateFields()) {
      return;
    }

    setOpen(true);
    clearErrors();

    login(email, password, "")
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data));
        if (response.data.userInfo.privacy_term) {
          localStorage.setItem(
            "privacy_term",
            JSON.stringify(response.data.userInfo.privacy_term)
          );
          history.push("/select-home");
        } else {
          localStorage.setItem(
            "privacy_term",
            JSON.stringify(response.data.userInfo.privacy_term)
          );
          history.push("/privacy-term");
        }
        setIsLogged(!isLogged);
      })
      .catch((error: AxiosError) => {
        const errorKey = error.response?.data.errors[0].key;

        if (errorKey === "user.not.exist") {
          setEmailError("Email não cadastrado");
        } else if (errorKey === "user.authentication.wrong.credential") {
          setPasswordError("Senha incorreta. Tente novamente");
        }

        setIsLogged(false);
      })
      .finally(() => setOpen(false));
  };

  const sendEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      sendLogin();
    }
  };

  const recoverPassword = () => {
    history.push("recover-password");
  };

  const changeVisibilityPassword = () => {
    setVisibilityPassword(!visibilityPassword);
  };

  const openTermModal = () => {
    setOpenModal(true);
  };

  const closeTermModal = () => {
    setOpenModal(false);
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.containerLogin}
        style={{ display: showDrawerCreateAccount ? "none" : "flex" }}
      >
        <div className={classes.formContainer}>
          <div className={classes.form}>
            <img
              className={classes.img}
              src="assets/imgs/logo.svg"
              height="70"
            />

            <Typography variant="h6" align="left" className={classes.textTitle}>
              Bem-vindo(a) ao Izy Manager
            </Typography>

            <Typography
              variant="caption"
              align="left"
              className={classes.textSubtitle}
            >
              Por favor, digite seu login e senha abaixo
            </Typography>

            <TextField
              id="input_emailLogin"
              label="E-mail"
              onChange={handleChangeEmail}
              name="email"
              variant="standard"
              className={classes.textField}
              error={!!emailError}
              helperText={emailError}
              InputProps={{
                classes: {
                  underline: classes.underline,
                },
              }}
            />
            <TextField
              id="input_passwordLogin"
              label="Senha"
              type={visibilityPassword ? "text" : "password"}
              onChange={handleChangePassword}
              onKeyDown={sendEnter}
              name="password"
              variant="standard"
              className={classes.textField}
              error={!!passwordError}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={changeVisibilityPassword}>
                      {visibilityPassword ? (
                        <Visibility style={{ color: "#9ca3af" }} />
                      ) : (
                        <VisibilityOff style={{ color: "#9ca3af" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  underline: classes.underline,
                },
              }}
            />

            <div className={classes.containerButtons}>
              <div className={classes.passwordLink} onClick={recoverPassword}>
                Esqueceu sua senha?
              </div>
            </div>

            <Button
              onClick={sendLogin}
              variant="contained"
              size="large"
              color="secondary"
              className={classes.loginButton}
              type="submit"
            >
              Fazer Login
            </Button>

            <Typography className={classes.createAccountText}>
              <b>Ainda não tem uma conta?</b>{" "}
              <a href="#" onClick={handleCreateAccountClick}>
                Cadastre-se aqui.
              </a>
            </Typography>

            <div className={classes.bottomContainerLogin}>
              <Typography
                className={classes.textSubtitleModal}
                onClick={openTermModal}
                style={{
                  textAlign: "center",
                }}
              >
                Termos de uso e políticas de privacidade
              </Typography>
            </div>
          </div>
        </div>

        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Dialog open={openModal} onClose={closeTermModal}>
          <DialogContent>
            <TextOfTerm />
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                textTransform: "none",
                marginRight: "15px",
              }}
              onClick={closeTermModal}
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        <DrawerCreateAccount
          open={showDrawerCreateAccount}
          onClose={() => setShowDraweCreaterAccount(false)}
        />
      </div>
    </div>
  );
};

export default LoginPage;
