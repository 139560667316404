import { makeStyles } from "@material-ui/core/styles";

const useLinkAccountModalStyles = makeStyles((theme) => ({
  modalContainer: {
    minWidth: 600,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.background.paper,
    padding: "20px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  modalHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  modalTitle: {
    fontWeight: "bold",
    fontSize: "28px",
  },
  modalInputsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  modalButtonsContainer: {
    display: "flex",
    justifyContent: "end",
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
}));

export default useLinkAccountModalStyles;
