import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  title: {
    fontSize: "23px",
    fontWeight: 600,
    lineHeight: "31px",
  },
  subTitles: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "25px",
  },
  propertyName: {
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "20px",
  },
  propertyValue: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "20px",
  },
});
