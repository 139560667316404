import React from "react";
import {
  Box,
  Typography,
  Slider,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  boldLabel: {
    fontWeight: 600,
    fontSize: "14px",
  },
});

interface CustomSliderProps {
  label: string;
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  step: number;
  unit?: string;
  labelClassName?: string; // Nova prop opcional
}

export const CustomSlider: React.FC<CustomSliderProps> = ({
  label,
  value,
  onChange,
  min,
  max,
  step,
  unit = "%",
  labelClassName,
}) => {
  const classes = useStyles();

  return (
    <Box className="px-1">
      <Box className="flex justify-between">
        <Typography
          className={`${classes.boldLabel} ${labelClassName}`}
          gutterBottom
        >
          {label}
        </Typography>
        <Typography gutterBottom>
          {value} {unit}
        </Typography>
      </Box>
      <Slider
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={(_, newValue) => onChange(newValue as number)}
        color="secondary"
      />
    </Box>
  );
};

interface CustomSelectProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  options: Array<{ value: string; label: string }>;
  labelClassName?: string;
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
  label,
  value,
  onChange,
  options,
  labelClassName,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography
        className={`${classes.boldLabel} ${labelClassName}`}
        gutterBottom
      >
        {label}
      </Typography>
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value as string)}
        fullWidth
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
