import React, { useContext, useEffect, useState } from "react";
import AllDevicesContext from "../../Context/AllDevicesContext";
import ReplyIcon from "@material-ui/icons/Reply";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import { FormControl, IconButton, MenuItem } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import WithRoleAuthorization from "../../../../Components/WithRoleAuthorization";

import * as S from "./styled";
import InputOutlined from "../../../../Components/CustomInput/InputOutlined";
import { SyncButton } from "../../../../Components/SyncButton";

const Filters: React.FunctionComponent = () => {
  const {
    changeRelocateStatus,
    changeRemoveDeviceStatus,
    setFilterNameValue,
    setFilterPlaceValue,
    setFilterModelValue,
    removeDevice,
    relocate,
  } = useContext(AllDevicesContext);
  const { setDevicesToRelocate, setExpandSubHeader, places, devices } =
    useContext(GlobalContex);

  const [modelDevices, setModelDevices] = useState<string[]>([]);

  useEffect(() => {
    const arrayDevices: string[] = [];
    devices.map((device) => {
      if (!arrayDevices.includes(device.product_name)) {
        arrayDevices.push(device.product_name);
      }
    });
    arrayDevices.sort();
    setModelDevices(arrayDevices);
  }, [devices]);

  const clickRealocate = () => {
    if (relocate && !removeDevice) return;
    changeRelocateStatus(true);
    changeRemoveDeviceStatus(false);
    setDevicesToRelocate([]);
  };

  const clickExpand = () => {
    setExpandSubHeader(false);
  };

  const clickRemove = () => {
    if (removeDevice) return;
    changeRelocateStatus(true);
    changeRemoveDeviceStatus(true);
    setDevicesToRelocate([]);
  };

  const handleChangeFilterNameValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterNameValue(event.target.value);
  };

  const handleChangeFilterPlaceValue = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFilterPlaceValue(event.target.value as string);
  };

  const handleChangeFilterModelValue = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFilterModelValue(event.target.value as string);
  };

  return (
    <S.Container>
      <S.Content>
        <S.Filters>
          <InputOutlined
            label={"Pesquisar por nome"}
            id={"filter-name-device"}
            onChange={handleChangeFilterNameValue}
            fullWidth
            variant="outlined"
          />

          <FormControl fullWidth>
            <InputOutlined
              label={"Filtrar local"}
              select
              id="filter-place-device"
              onChange={handleChangeFilterPlaceValue}
              defaultValue=""
              variant="outlined"
            >
              <MenuItem value="">
                <em style={{ color: "#8B979F" }}>Limpar</em>
              </MenuItem>
              {places.map((place, index) => (
                <MenuItem key={"place-item-" + index} value={place.name}>
                  {place.name}
                </MenuItem>
              ))}
            </InputOutlined>
          </FormControl>

          <FormControl fullWidth>
            <InputOutlined
              select
              label={"Filtrar modelo"}
              id="filter-model-device"
              onChange={handleChangeFilterModelValue}
              defaultValue=""
              variant="outlined"
            >
              <MenuItem value="">
                <em style={{ color: "#8B979F" }}>Limpar</em>
              </MenuItem>
              {modelDevices.map((device, index) => (
                <MenuItem key={"model-item-" + index} value={device}>
                  {device}
                </MenuItem>
              ))}
            </InputOutlined>
          </FormControl>
        </S.Filters>
        <S.Actions>
          <SyncButton />
          <WithRoleAuthorization permitedRoles={["Owner", "Administrador"]}>
            <S.ButtonFilledStyled
              id={"btn-remove"}
              className={"delete"}
              startIcon={<DeleteOutlineIcon />}
              color="secondary"
              size={"large"}
              onClick={clickRemove}
            >
              Remover <span className={"hidden"}>&nbsp;dispositivos</span>
            </S.ButtonFilledStyled>
            <S.ButtonFilledStyled
              id={"btn-relocate"}
              size={"large"}
              startIcon={<ReplyIcon />}
              color="secondary"
              onClick={clickRealocate}
            >
              Realocar <span className={"hidden"}>&nbsp;dispositivos</span>
            </S.ButtonFilledStyled>
          </WithRoleAuthorization>
        </S.Actions>
      </S.Content>
      <S.CloseHeader>
        <IconButton onClick={clickExpand}>
          <img src="assets/icons/close-header-icon.svg" />
        </IconButton>
      </S.CloseHeader>
    </S.Container>
  );
};

export default Filters;
