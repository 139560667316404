import { useState } from "react";
import { Button, makeStyles, createStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { UserLogin } from "../../Models/UserLogin";
import { getCurrentUser } from "../../Services/AuthService";
import { useImageLoader } from "../../hooks";

const useStyles = makeStyles(() =>
  createStyles({
    layout: {
      backgroundColor: "#EFEFED",
      height: "100vh",
      padding: "0px",
      margin: "0px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    imageContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    image: {
      width: "400px",
      height: "auto",
      opacity: 0,
      transition: "opacity 1s ease-in-out",
    },
    imageLoaded: {
      opacity: 1,
    },
    title: {
      color: "#40464D",
      fontWeight: 700,
    },
  })
);

const OnboardingComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [user] = useState<UserLogin>(getCurrentUser());
  const { imageLoaded, handleImageLoad } = useImageLoader();

  const { name } = user?.userInfo || {};

  const handleButtonClick = () => {
    history.push("/sync-account");
  };

  return (
    <div className={classes.layout}>
      <div className={classes.imageContainer}>
        <img
          src="assets/imgs/onboarding.svg"
          alt="Onboarding"
          className={`${classes.image} ${
            imageLoaded ? classes.imageLoaded : ""
          }`}
          onLoad={handleImageLoad}
        />
      </div>
      {imageLoaded && (
        <div style={{ textAlign: "center" }}>
          <h1 className={classes.title}>Olá, {name}!</h1>
          <p>É muito bom te ver por aqui.</p>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            fullWidth
            onClick={handleButtonClick}
          >
            COMEÇAR
          </Button>
        </div>
      )}
    </div>
  );
};

export default OnboardingComponent;
