import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Drawer,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { DeviceControlPanel } from "./DeviceControlPanel";
import { deviceModelControls } from "./deviceControlsConfig";
import { CONTENT_SIZE, FOOTER_HEIGHT, HEADER_HEIGHT } from "./constants";
import GlobalContext from "../../../GlobalContext/GlobalContext";
import AllDevicesContext from "../../../Pages/AllDevices/Context/AllDevicesContext";
import ButtonText from "../../CustomButton/ButtonText";
import { useStyles } from "./styles";
import {
  updateImouDevice,
  updateImouPropsDevice,
  getImouDeviceProperties,
} from "../../../Services/ImouDevicesService";
import { IImouDeviceDrawerProps } from "./types";

interface FormState {
  name: string;
  [key: string]: any;
}

const ImouDeviceDrawer: React.FC<IImouDeviceDrawerProps> = ({
  open,
  device,
  handleClose,
}) => {
  const classes = useStyles();
  const { showAlert } = useContext(GlobalContext);
  const { fetchImouDevices } = useContext(AllDevicesContext);
  const [formState, setFormState] = useState<FormState>({ name: "" });
  const [isLoading, setIsLoading] = useState(false);

  const controls = deviceModelControls[device.deviceModel] || [];

  const updateFormField = (field: string) => (value: any) => {
    setFormState((prev) => ({ ...prev, [field]: value }));
  };

  const handleSaveEdit = async () => {
    setIsLoading(true);
    try {
      const visibleControls = controls.filter((control) => {
        if (control.showIf) {
          return formState[control.showIf.field] === control.showIf.value;
        }
        return true;
      });

      const masterSwitchControl = visibleControls.find(
        (control) => control.field === "masterSwitch"
      );
      const otherControls = visibleControls.filter(
        (control) => control.field !== "masterSwitch"
      );

      await updateImouDevice({
        deviceId: device.deviceId,
        newName: formState.name,
        alias: device.alias,
      });

      if (masterSwitchControl) {
        await updateImouPropsDevice({
          deviceId: device.deviceId,
          alias: device.alias,
          productId: device.productId,
          properties: {
            [masterSwitchControl.propertyId]:
              formState[masterSwitchControl.field],
          },
        });
      }

      const properties: { [key: string]: any } = {};
      const jsonGroups: { [key: string]: any } = {};

      otherControls.forEach((control) => {
        if (control.jsonProperty) {
          const propKey = control.jsonProperty.property;
          if (!jsonGroups[propKey]) {
            jsonGroups[propKey] = control.jsonProperty.isArray ? [{}] : {};
          }

          if (control.jsonProperty.isArray) {
            jsonGroups[propKey][0][control.jsonProperty.field] =
              formState[control.field];
          } else {
            jsonGroups[propKey][control.jsonProperty.field] =
              formState[control.field];
          }
        } else if (control.propertyId) {
          properties[control.propertyId] = formState[control.field];
        }
      });

      Object.entries(jsonGroups).forEach(([key, value]) => {
        properties[key] = JSON.stringify(value);
      });

      if (Object.keys(properties).length > 0) {
        await updateImouPropsDevice({
          deviceId: device.deviceId,
          alias: device.alias,
          productId: device.productId,
          properties,
        });
      }

      showAlert("O dispositivo foi editado com sucesso!", "success");
      handleClose();
      await fetchImouDevices();
    } catch (error) {
      showAlert("Ocorreu um erro ao tentar editar o dispositivo!", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const [isLoadingProperties, setIsLoadingProperties] = useState(false);

  useEffect(() => {
    if (!open) return;

    const fetchDeviceProperties = async () => {
      setIsLoadingProperties(true);

      try {
        const newFormState: FormState = { name: device.deviceName };

        if (deviceModelControls[device.deviceModel]) {
          const propertyIds = controls
            .map(
              (control) => control.jsonProperty?.property || control.propertyId
            )
            .filter((value, index, self) => self.indexOf(value) === index);

          const response = await getImouDeviceProperties({
            deviceId: device.deviceId,
            alias: device.alias,
            productId: device.productId,
            properties: propertyIds,
          });

          const properties = response.data.result.data.properties;

          controls.forEach((control) => {
            if (control.jsonProperty) {
              const propValue = properties[control.jsonProperty.property];
              if (propValue) {
                try {
                  const parsedValue = JSON.parse(propValue);
                  if (
                    control.jsonProperty.isArray &&
                    Array.isArray(parsedValue)
                  ) {
                    newFormState[control.field] =
                      parsedValue[0][control.jsonProperty.field];
                  } else {
                    newFormState[control.field] =
                      parsedValue[control.jsonProperty.field];
                  }
                } catch (e) {
                  console.error(
                    `Error parsing JSON property ${control.jsonProperty.property}:`,
                    e
                  );
                }
              }
            } else {
              if (properties[control.propertyId] !== undefined) {
                newFormState[control.field] = properties[control.propertyId];
              } else {
                newFormState[control.field] = control.defaultValue;
              }
            }
          });
        }

        setFormState(newFormState);
      } catch (error) {
        showAlert("Erro ao carregar as propriedades do dispositivo!", "error");
      } finally {
        setIsLoadingProperties(false);
      }
    };

    fetchDeviceProperties();
  }, [open, device]);

  return (
    <Drawer open={open} anchor="right" onClose={handleClose}>
      <Box
        style={{
          minWidth: "600px",
          backgroundColor: "#FFF",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          style={{
            height: HEADER_HEIGHT,
            borderBottom: "1px solid #e3e3e3",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <Box
            style={{ display: "flex", gridGap: "10px", alignItems: "center" }}
          >
            <SettingsIcon />
            <Typography className={classes.title}>Configurações</Typography>
          </Box>

          <ButtonText
            startIcon={
              <img
                src="assets/icons/icon-close-drawer-application.svg"
                alt="Fechar"
              />
            }
            onClick={handleClose}
            size="small"
            style={{ fontWeight: "normal" }}
          >
            Sair
          </ButtonText>
        </Box>

        <Box
          style={{
            height: CONTENT_SIZE,
            padding: "20px 50px",
            display: "flex",
            flexDirection: "column",
            gridGap: "25px",
            overflow: "auto",
          }}
        >
          <Typography className={classes.subTitles}>
            Editar dispositivo
            <Box>
              <span className={classes.propertyValue}>
                {device.deviceModel}
              </span>
              <br />
              <span style={{ fontSize: "12px", fontWeight: 400 }}>
                Id: {device.deviceId}
              </span>
            </Box>
          </Typography>

          {isLoadingProperties && (
            <div
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <CircularProgress color="secondary" size={56} />
            </div>
          )}

          <DeviceControlPanel
            deviceModel={device.deviceModel}
            formState={formState}
            updateFormField={updateFormField}
            controls={controls}
            isLoading={isLoadingProperties}
          />
        </Box>

        <Box
          style={{
            height: FOOTER_HEIGHT,
            borderTop: "1px solid #e3e3e3",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveEdit}
            disabled={isLoading || !formState.name.trim()}
          >
            {isLoading ? <CircularProgress size={24} /> : "Salvar"}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default ImouDeviceDrawer;
