import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { useContext } from "react";
import DeviceModel from "../../../../Models/DeviceModel";
import EnvironmentsContext from "../../context/EnvironmentsContext";
import SubDevice from "../SubDevice";

const useStyles = makeStyles(() => ({
  loadingEnv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100px",
  },
}));

const DevicesAssociate: React.FunctionComponent = () => {
  const {
    devicesAssociated,
    devicesNotAssociated,
    loadingEnvironments,
    filterValue,
  } = useContext(EnvironmentsContext);

  const classes = useStyles();

  if (loadingEnvironments) {
    return (
      <div className={classes.loadingEnv}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      <Grid container spacing={4} direction="row" justifyContent="center">
        {devicesAssociated?.map((device: DeviceModel) => {
          if (device.name.toLowerCase().includes(filterValue.toLowerCase())) {
            return (
              <Grid item xs={3} key={`subdevice-grid-${device.id}`}>
                <SubDevice device={device} selectedProps={true} />
              </Grid>
            );
          }
        })}
      </Grid>
      <p
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#8B979F",
          display: "flex",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        Dispositivos que não estão neste ambiente
      </p>
      <Grid container spacing={4} direction="row" justifyContent="center">
        {devicesNotAssociated.map((device: DeviceModel) => {
          if (device.name.toLowerCase().includes(filterValue.toLowerCase())) {
            return (
              <Grid item xs={3} key={`subdevice-grid-${device.id}`}>
                <SubDevice
                  device={device}
                  selectedProps={false}
                  isNotAssocied={true}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </div>
  );
};

export default DevicesAssociate;
