import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";
import { HeaderLite } from "../../Components/HeaderLite";
import { LinkAccount } from "./Components/LinkAccount";
import { MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Icon from "../../Components/Icon";
import { logout } from "../../Services/AuthService";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    backgroundColor: "#EFEFED",
  },
  sideMenu: {
    width: "274px",
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 140px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px",
    borderRadius: "24px",
    margin: "40px 24px",
    border: "solid 1px rgba(0, 163, 53, 0.2)",
  },
  layout: {
    height: "100%",
    width: "calc(100vw - 274px)",
    minHeight: "calc(100vh - 140px)",
    padding: "0px",
    margin: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "24px",
    marginTop: "40px",
  },
  wrapperTabs: {
    backgroundColor: "#fff",

    alignSelf: "stretch",
    flexGrow: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flexStart",
    gap: "10px",
    padding: "22px 24px",
    borderRadius: "24px",
    border: "24px solid #F4F4F2",
  },
  contentTabs: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  tabContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  title: {
    color: "#40464D",
    fontWeight: 600,
    fontSize: "18px",
    margin: "0px",
  },
  tab: {
    textTransform: "none",
    borderBottom: "solid 3px #d9dfe6",
    "&.Mui-selected": {
      color: "#00a335",
    },
  },
  tabCustom: {
    width: "100%",
  },
  menuItem: {
    width: "100%",
    borderBottom: "solid 1px rgba(0, 0, 0, 0.04)",
    gap: "20px",
    "&:hover": {
      backgroundColor: "transparent",
      borderBottom: "solid 3px rgba(0, 0, 0, 0.07)",
    },
  },
  footer: {
    width: "100%",
  },
  footerMenuItem: {
    borderBottom: "none",
    "&:hover": {
      borderBottom: "none",
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

const ConfigAccount: React.FC = () => {
  const classes = useStyles();
  const [value, setValue] = useState(2);
  const history = useHistory();

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  const handleClickBackToHome = () => {
    history.push("/");
  };

  return (
    <>
      <HeaderLite />
      <div className={classes.container}>
        <div className={classes.sideMenu}>
          <MenuItem
            className={classes.menuItem}
            aria-label="vincular conta mibo"
            onClick={handleClickBackToHome}
          >
            <Icon src="sideMenu/home" alt="go to home icon" /> Voltar ao início
          </MenuItem>
          <div className={classes.footer}>
            <MenuItem
              className={`${classes.menuItem} ${classes.footerMenuItem}`}
              aria-label="sair"
              onClick={() => logout()}
            >
              <Icon src="sideMenu/logout" alt="logout" /> Log out
            </MenuItem>
          </div>
        </div>
        <div className={classes.layout}>
          <div className={classes.wrapperTabs}>
            <h1 className={classes.title}>Perfil e configurações</h1>
            <div className={classes.contentTabs}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="principais configurações"
              >
                <Tab
                  label="Informações da conta"
                  disabled
                  className={classes.tab}
                />
                <Tab label="Mudar senha" disabled className={classes.tab} />
                <Tab label="Contas smart" className={classes.tab} />
                <Tab label="Preferências" disabled className={classes.tab} />
              </Tabs>
              <TabPanel value={value} index={0}>
                Informações da conta
              </TabPanel>
              <TabPanel value={value} index={1}>
                Mudar senha
              </TabPanel>
              <div className={classes.tabCustom}>
                <TabPanel value={value} index={2}>
                  <LinkAccount />
                </TabPanel>
              </div>
              <TabPanel value={value} index={3}>
                Preferências
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigAccount;
