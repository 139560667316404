import Paper from "@material-ui/core/Paper";
import { ImouDeviceProps } from "./types";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Switch, Typography } from "@material-ui/core";
import { ImouDeviceDrawer } from "../../../../Components/Drawer/ImouDeviceDrawer";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  updateImouPropsDevice,
  getImouDeviceProperties,
} from "../../../../Services/ImouDevicesService";
import GlobalContext from "../../../../GlobalContext/GlobalContext";
import { deviceModelControls } from "../../../../Components/Drawer/ImouDeviceDrawer/deviceControlsConfig";

const ImouDevice = ({ device }: ImouDeviceProps) => {
  const useStyles = makeStyles(() =>
    createStyles({
      paper_item: {
        display: "flex",
        padding: "19px",
        height: "190px",
        borderRadius: "20px",
        alignContent: "space-between",
        flexDirection: "column",
      },
      paper_content: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      paperContentName: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        flex: 1,
      },
      deviceName: {
        margin: "0px",
        color: "#3E5055",
        fontWeight: "bold",
        fontSize: "15px",
      },
      deviceNameRoomName: {
        margin: "0px",
        color: "#8B979F",
        fontWeight: "normal",
        fontSize: "13px",
      },
      offline: {
        fontSize: "12px",
        color: "#8B979F",
      },
      switch: {
        color: "#8B979F",
        fontSize: "12px !important",
      },
    })
  );

  const classes = useStyles();
  const { showAlert } = useContext(GlobalContext);

  const deviceConfig = deviceModelControls[device.deviceModel] || [];
  const masterSwitchConfig = deviceConfig.find(
    (control) => control.field === "masterSwitch"
  );
  const showConfigFields = !!masterSwitchConfig;
  const masterSwitchPropertyId = masterSwitchConfig?.propertyId || "";

  interface TextProps {
    text: string;
  }
  const FiledText = ({ text }: TextProps) => {
    const classes = useStyles();
    return <Typography className={classes.switch}>{text}</Typography>;
  };

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [switchChecked, setSwitchChecked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchDeviceProperties = async () => {
    if (!showConfigFields) return;

    try {
      setIsLoading(true);
      const response = await getImouDeviceProperties({
        deviceId: device.deviceId,
        alias: device.alias,
        productId: device.productId,
        properties: [masterSwitchPropertyId],
      });

      const properties = response.data.result.data.properties;
      const switchState = properties[masterSwitchPropertyId] === 1;
      setSwitchChecked(switchState);
    } catch (error) {
      showAlert("Erro ao obter o estado do dispositivo", "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (showConfigFields) {
      fetchDeviceProperties();
    }
  }, [device.deviceId, device.alias, device.productId, masterSwitchPropertyId]);

  useEffect(() => {
    if (!openDrawer) {
      fetchDeviceProperties();
    }
  }, [openDrawer]);

  const handleCloseDrawer = useCallback(
    () => setOpenDrawer(false),
    [setOpenDrawer]
  );

  const handleOpenDrawer = useCallback(
    () => setOpenDrawer(true),
    [setOpenDrawer]
  );

  const handleSwitchChange = async (checked: boolean) => {
    const previousStatus = switchChecked;
    setSwitchChecked(checked);

    const newStatus = checked ? 1 : 0;
    try {
      await updateImouPropsDevice({
        deviceId: device.deviceId,
        alias: device.alias,
        productId: device.productId,
        properties: { [masterSwitchPropertyId]: newStatus },
      });
      showAlert("O Status do dispositivo foi editado com sucesso!", "success");
    } catch {
      setSwitchChecked(previousStatus);
      showAlert(
        "Ocorreu um erro ao tentar alterar o status do dispositivo!",
        "error"
      );
    }
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          cursor: "pointer",
        }}
      >
        <Paper
          style={{
            backgroundColor: device.deviceStatus ? "#FFF" : "#F2F2F2",
            filter: device.deviceStatus ? "" : "grayscale(80%)",
            cursor: "pointer",
            position: "relative",
          }}
          onClick={handleOpenDrawer}
          className={classes.paper_item}
        >
          <div className={classes.paper_content}>
            <img
              style={{
                opacity: "1",
                filter: "grayscale(80%)",
                borderRadius: "10px",
              }}
              src="./assets/imgs/enterpriseDefaultImage.png"
              height="58"
              alt="Ícone do dispositivo"
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              <span>
                {switchChecked ? (
                  <FiledText text={"Ligado"} />
                ) : (
                  <FiledText text="Desligado" />
                )}
              </span>
              {showConfigFields && (
                <Switch
                  checked={switchChecked}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => handleSwitchChange(e.target.checked)}
                  disabled={isLoading}
                />
              )}
            </div>
          </div>

          <div className={classes.paperContentName}>
            <p className={classes.deviceName}> {device.deviceName} </p>
            <p className={classes.deviceNameRoomName}>{device.deviceModel}</p>
            <p className={classes.deviceNameRoomName}>{device.deviceVersion}</p>
          </div>
        </Paper>
      </div>
      <ImouDeviceDrawer
        open={openDrawer}
        device={device}
        handleClose={handleCloseDrawer}
      />
    </>
  );
};

export default ImouDevice;
