const useMockApi = localStorage.getItem("apiFake") === "true";

export const API_URL = useMockApi
  ? process.env.REACT_APP_MOCK_API_URL
  : process.env.REACT_APP_API_URL;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || "";
export const AUTH_IMOU_CLIENT_ID =
  process.env.REACT_APP_AUTH_IMOU_CLIENT_ID_DEVELOP || "";

export const isRunningLocalEnvironment = process.env.REACT_APP_ENV === "local";
