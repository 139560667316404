import {
  ChangeEvent,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { LinkAccountModalProps } from "./LinkAccountModalTypes";
import useLinkAccountModalStyles from "./LinkAccountModalStyles";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { linkImouAccount } from "../../../Services/UserService";
import GlobalContext from "../../../GlobalContext/GlobalContext";

const LinkAccountModal = ({ id, modalRef }: LinkAccountModalProps) => {
  const classes = useLinkAccountModalStyles();
  const { showAlert } = useContext(GlobalContext);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [linkCode, setLinkCode] = useState<string>("");

  const handleChangeEmail = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setEmail(event.target.value);
  };

  const handleChangeName = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setName(event.target.value);
  };

  const handleClickConfirm = async () => {
    setIsLoading(true);
    await linkImouAccount(email, name, linkCode)
      .then(() => {
        showAlert("Conta vinculada com sucesso", "success");
      })
      .catch(() => {
        showAlert("Ocorreu um erro na requisição!", "error");
      })
      .finally(() => {
        setIsLoading(false);
        handleCloseModal();
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    resetModal();
  };

  const resetModal = () => {
    setEmail("");
    setIsLoading(false);
  };

  useImperativeHandle(
    modalRef,
    () => {
      return {
        openModal: (code) => {
          setLinkCode(code);
          setOpenModal(true);
        },
      };
    },
    []
  );

  useEffect(() => {
    return () => {
      resetModal();
    };
  }, []);

  return (
    <Modal open={openModal}>
      <Box className={classes.modalContainer}>
        <Box className={classes.modalHeaderContainer}>
          <Typography className={classes.modalTitle} component={"h1"}>
            Vincular conta Mibo
          </Typography>
        </Box>
        <Box className={classes.modalInputsContainer}>
          <TextField
            id={`${id}-email-input`}
            label="E-mail Mibo"
            onChange={handleChangeEmail}
            name="email"
            variant="filled"
          />
          <TextField
            id={`${id}-name-input`}
            label="Apelido"
            onChange={handleChangeName}
            name="name"
            variant="filled"
          />
        </Box>
        <Box className={classes.modalButtonsContainer}>
          <Button
            id={`${id}-confirm-btn`}
            onClick={handleClickConfirm}
            variant="contained"
            color="secondary"
            disabled={isLoading}
          >
            Confirmar {isLoading && <CircularProgress size={"20px"} />}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LinkAccountModal;
