import { PropsWithChildren, ReactNode } from "react";
import ChangeToNewPlanContext from "./ChangeToNewPlanSuccessContext";
import { useLocation } from "react-router-dom";
import { Plans, Price } from "../../../Models/Plans";
import { RegisterEnterpriseModel } from "../../../Models/EnterpriseModel";

interface LocationRedirect {
  enterpriseInfo: RegisterEnterpriseModel & { id: string };
  price: Price;
  plan: Plans;
}

const ChangeToNewPlanSuccessProvider: React.FunctionComponent = (
  props: PropsWithChildren<ReactNode>
) => {
  const { children } = props;
  const dataRedirect = useLocation<LocationRedirect>();
  const { plan, price, enterpriseInfo } = dataRedirect.state;

  return (
    <ChangeToNewPlanContext.Provider
      value={{ price, plan, enterprise: enterpriseInfo }}
    >
      {children}
    </ChangeToNewPlanContext.Provider>
  );
};

export default ChangeToNewPlanSuccessProvider;
