import React, { FunctionComponent } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSmartAccounts } from "../../hooks";
import { getPrivacyTerWasAccepted } from "../../Services/AuthService";
import { getHomeIDSelect } from "../../Services/UserService";

interface Props {
  path: string;
  component: FunctionComponent<unknown>;
  exact?: boolean;
  needEnterprise?: boolean;
}

const ProtectedRoute: React.FunctionComponent<Props> = ({
  needEnterprise = true,
  component: Component,
  path,
  exact,
}: Props) => {
  const isAuthenticated = localStorage.getItem("user");
  const smartAccounts = useSmartAccounts();

  return (
    <Route
      path={path}
      exact={exact}
      render={() =>
        isAuthenticated && getPrivacyTerWasAccepted() ? (
          path === "/onboarding" ? (
            <Component />
          ) : smartAccounts ? (
            <Redirect to="/onboarding" />
          ) : needEnterprise ? (
            Number(getHomeIDSelect()) > 0 ? (
              <Component />
            ) : (
              <Redirect to="/select-home" />
            )
          ) : (
            <Component />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default ProtectedRoute;
