import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import ProtectedRoute from "./Components/ProtectedRoute";
import HomePage from "./Pages/HomePage";
import ManageRoom from "./Pages/ManageRoom";
import ManagePlace from "./Pages/ManagePlace";
import LoginPage from "./Pages/LoginPage";
import ProfilePage from "./Pages/ProfilePage/";
import history from "./Services/history";
import Page404 from "./Pages/404";
import UsersPage from "./Pages/UsersPage";
import AutomationPage from "./Pages/AutomationPage";
import OperatorsGroupPage from "./Pages/OperatorsGroupPage";
import SelectHomePage from "./Pages/SelectHomePage";
import AllDevices from "./Pages/AllDevices";
import RecoverPassword from "./Pages/RecoverPassword";
import NotificationConfigPage from "./Pages/NotificationConfigPage";
import PrivacyTermPage from "./Pages/PrivacyTermPage";
import OperatorsGroupCreatePage from "./Pages/OperatorsGroupCreatePage";
import OperatorsGroupEditPage from "./Pages/OperatorsGroupEditPage";
import EnterpriseCreatePage from "./Pages/EnterpriseCreatePage";
import SuccessPaymentPage from "./Pages/SuccessPaymentPage";
import EnterpriseEditPage from "./Pages/EnterpriseEditPage";
import EnterprisesConfigPage from "./Pages/EnterprisesConfigPage";
import TrialPlanPage from "./Pages/TrialPlanPage";
import ChangeToNewPlan from "./Pages/ChangeToNewPlan";
import ChangeToNewPlanSuccess from "./Pages/ChangeToNewPlanSuccess";
import ConfirmCodePage from "./Pages/ConfirmCodePage";
import CancelSignaturePage from "./Pages/CancelSignaturePage";
import AuthCode from "./Pages/AuthCode";
import Onboarding from "./Pages/Onboarding";
import SyncAccount from "./Pages/SyncAccount";
import ConfigAccount from "./Pages/ConfigAccount";

export const Routes: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" exact component={LoginPage} />
        <Route path="/auth" exact component={AuthCode} />
        <ProtectedRoute path="/" exact component={HomePage} />
        <ProtectedRoute
          path="/automation-dashboard"
          exact
          component={AutomationPage}
        />
        <ProtectedRoute path="/usuarios" component={UsersPage} />
        <ProtectedRoute
          path="/operators"
          component={OperatorsGroupPage}
          exact
        />
        <ProtectedRoute
          path="/operators/create"
          component={OperatorsGroupCreatePage}
        />
        <ProtectedRoute
          path="/operators/edit/:id"
          component={OperatorsGroupEditPage}
        />
        <ProtectedRoute
          needEnterprise={false}
          path="/enterprise/create"
          component={EnterpriseCreatePage}
        />
        <ProtectedRoute
          needEnterprise={false}
          path="/enterprise/success-payment"
          component={SuccessPaymentPage}
        />
        <ProtectedRoute
          needEnterprise={false}
          path="/enterprise/trial-plan"
          component={TrialPlanPage}
        />
        <ProtectedRoute
          needEnterprise={false}
          path="/change-to-new-plan"
          component={ChangeToNewPlan}
        />
        <ProtectedRoute
          needEnterprise={false}
          path="/change-to-new-plan-success"
          component={ChangeToNewPlanSuccess}
        />
        <ProtectedRoute
          needEnterprise={false}
          path="/enterprise/edit/:id"
          component={EnterpriseEditPage}
        />
        <ProtectedRoute
          needEnterprise={false}
          path="/profile"
          component={ProfilePage}
        />
        <ProtectedRoute
          needEnterprise={false}
          path="/cancelar-assinatura"
          component={CancelSignaturePage}
        />
        <ProtectedRoute path="/manage-room" component={ManageRoom} />
        <ProtectedRoute path="/manage-place" component={ManagePlace} />
        <ProtectedRoute
          needEnterprise={false}
          path="/select-home"
          component={SelectHomePage}
        />
        <ProtectedRoute path="/all-devices" component={AllDevices} />
        <ProtectedRoute
          path="/notification-config"
          component={NotificationConfigPage}
        />
        <ProtectedRoute
          needEnterprise={false}
          path="/my-enterprises"
          component={EnterprisesConfigPage}
        />
        <ProtectedRoute path="/onboarding" exact component={Onboarding} />
        <ProtectedRoute
          needEnterprise={false}
          path="/sync-account"
          component={SyncAccount}
        />
        <ProtectedRoute
          needEnterprise={false}
          path="/config-account"
          component={ConfigAccount}
        />
        <Route path="/privacy-term" component={PrivacyTermPage} />
        <Route path="/recover-password" exact component={RecoverPassword} />
        <Route path="/confirm-code" exact component={ConfirmCodePage} />
        <Route path="*" exact component={Page404} />
      </Switch>
    </Router>
  );
};
