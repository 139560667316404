import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    logo: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff",
      height: "84px",
      width: "100%",
      borderBottom: "1px solid #E5E5E5",
      "& img": {
        width: "140px",
        height: "auto",
      },
    },
  })
);

export const HeaderLite = () => {
  const classes = useStyles();

  return (
    <div className={classes.logo}>
      <img src="assets/imgs/logo.svg" alt="Logo IZY" />
    </div>
  );
};
