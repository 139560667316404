import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AUTH_IMOU_CLIENT_ID } from "../../../../constants";
import { DataGridAccount } from "../DataGridAccount";

const useStyles = makeStyles(() => ({
  tabContentTitle: {
    color: "#3e5055",
    fontWeight: "bold",
    fontSize: "26px",
    margin: "0px",
    textAlign: "center",
  },
  ulContent: {
    marginBottom: "40px",
  },
  wrapperTabsContent: {
    margin: "0 auto",
  },
}));

export const LinkAccount = () => {
  const classes = useStyles();

  const handleLinkAccount = () => {
    window.location.href = `https://auth.imoulife.com/login-apply?clientId=${AUTH_IMOU_CLIENT_ID}&loginType=apply&client=apply`;
  };

  const isAccountLinked = true;

  return (
    <div className={classes.wrapperTabsContent}>
      {isAccountLinked ? (
        <DataGridAccount />
      ) : (
        <>
          <h2 className={classes.tabContentTitle}>Vincule sua Conta Smart</h2>
          <div>
            <p>
              Para gerenciar seus dispositivos inteligentes com eficiência, é
              necessário vincular sua Conta Smart. Ao fazer isso, você poderá:
            </p>
            <ul className={classes.ulContent}>
              <li>Reconhecer seus dispositivos conectados automaticamente;</li>
              <li>
                Organizar dispositivos por empreendimento e local, facilitando o
                controle;
              </li>
              <li>
                Criar automações personalizadas, otimizando suas operações.
              </li>
            </ul>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleLinkAccount}
            >
              Vincular Conta Mibo
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
