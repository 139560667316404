import { useState, useEffect } from "react";

export const useSmartAccounts = () => {
  const [smartAccounts, setSmartAccounts] = useState(false);

  useEffect(() => {
    const smartAccountsValue = localStorage.getItem("smartAccounts") === "true";
    setSmartAccounts(smartAccountsValue);
  }, []);

  return smartAccounts;
};
