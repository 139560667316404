import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  dialog: {
    minWidth: "500px",
  },
  dialogTitle: {
    "& h2": {
      color: "#035144",
      fontSize: "1.125rem",
      fontWeight: 700,
    },
  },
  headerDialog: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContentText: {
    color: "#3C3C3C",
    fontSize: "1rem",
    fontWeight: 400,
    margin: "24px 0 24px 0",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "24px",
  },
}));

interface CustomDialogV2Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  confirmText: string;
  cancelText: string;
}

const CustomDialogV2: React.FC<CustomDialogV2Props> = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
  confirmText,
  cancelText,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.dialog }}
    >
      <div className={classes.headerDialog}>
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          {title}
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <DialogContentText
          className={classes.dialogContentText}
          id="alert-dialog-description"
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          onClick={onClose}
          color="secondary"
          size="large"
        >
          {cancelText}
        </Button>

        <Button
          autoFocus
          color="secondary"
          variant="contained"
          onClick={onConfirm}
          size="large"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialogV2;
