import styled from "styled-components";

interface TextProps {
  fontWeight?: number;
  fontSize?: string;
  lineHeight?: string;
  marginTop?: string;
  color?: string;
}

interface WarningtProps {
  direction?: string;
  border?: string;
}

export const Warning = styled.div<WarningtProps>`
  color: #bdbdbd;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: ${(props) => props.direction ?? "column"};
  justify-content: center;
  width: auto;
  height: auto;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  transition: 0.5s ease;
  border-radius: ${(props) => props.border ?? "20px"};
`;

export const Text = styled.p<TextProps>`
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans";
  margin: 0px;
  text-align: center;
  font-style: normal;
  margin-top: ${(props) => props.marginTop};
  font-weight: ${(props) => props.fontWeight ?? 600};
  font-size: ${(props) => props.fontSize ?? "37px"};
  line-height: ${(props) => props.fontSize ?? "50px"};
`;
