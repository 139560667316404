import {
  getSmartAccount,
  deleteSmartAccount,
  SmartAccountModel,
} from "../../Services/SmartAccount";

export const fetchSmartAccount = async (userId: string) => {
  try {
    const response = await getSmartAccount(userId);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error("Error fetching smart account: " + error.message);
    } else {
      throw new Error("Error fetching smart account");
    }
  }
};

export const fetchAndSetSmartAccount = async (
  userId: string,
  setData: (data: SmartAccountModel[]) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: string | null) => void
) => {
  try {
    const data = await fetchSmartAccount(userId);
    setData(data);
    setLoading(false);
  } catch (error) {
    if (error instanceof Error) {
      setError(error.message);
    } else {
      setError("An unknown error occurred");
    }
    setLoading(false);
  }
};

export const removeSmartAccount = async (
  userId: string,
  accountId: number,
  setData: (data: SmartAccountModel[]) => void,
  setError: (error: string | null) => void
) => {
  try {
    await deleteSmartAccount(userId, accountId);
    const updatedData = await fetchSmartAccount(userId);
    setData(updatedData);
  } catch (error) {
    if (error instanceof Error) {
      setError(error.message);
    } else {
      setError("An unknown error occurred");
    }
  }
};

export const deleteAndSetSmartAccount = async (
  userId: string,
  accountId: number,
  setData: (data: SmartAccountModel[]) => void,
  setError: (error: string | null) => void,
  setOpen: (open: boolean) => void,
  prevData: SmartAccountModel[],
  setSnackbarOpen: (open: boolean) => void
) => {
  try {
    await removeSmartAccount(userId, accountId, setData, setError);
    setData(
      prevData.filter((account: SmartAccountModel) => account.id !== accountId)
    );
    setOpen(false);
    setSnackbarOpen(true);
  } catch (error) {
    console.error("Error deleting account:", error);
  }
};
