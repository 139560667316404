import React, { useEffect } from "react";
import {
  Box,
  TextField,
  Switch,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { CustomSlider, CustomSelect } from "./ControlInputs";
import { ControlConfig } from "./deviceControlsConfig";
import { CONTENT_SIZE } from "./constants";

const useStyles = makeStyles({
  label: {
    fontSize: "14px",
    fontWeight: 600,
    display: "block",
    marginBottom: "8px",
  },
  textField: {
    "& .MuiInputLabel-root": {
      fontWeight: 600,
    },
  },
});

interface DeviceControlPanelProps {
  deviceModel: string;
  formState: any;
  updateFormField: (field: string) => (value: any) => void;
  controls: ControlConfig[];
  isLoading?: boolean;
}

const shouldShowControl = (control: ControlConfig, formState: any): boolean => {
  if (control.showIf) {
    return formState[control.showIf.field] === control.showIf.value;
  }
  return true;
};

export const DeviceControlPanel: React.FC<DeviceControlPanelProps> = ({
  deviceModel,
  formState,
  updateFormField,
  controls,
  isLoading = false,
}) => {
  const classes = useStyles();

  const handleMasterSwitchChange = (
    control: ControlConfig,
    checked: boolean
  ) => {
    const value = checked ? 1 : 0;
    updateFormField(control.field)(value);

    if (control.controlsChildren) {
      control.controlsChildren.forEach((childField) => {
        updateFormField(childField)(value);
      });
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box
      style={{
        height: CONTENT_SIZE,
        padding: "0px 10px",
        display: "flex",
        flexDirection: "column",
        gridGap: "25px",
        overflow: "auto",
      }}
    >
      <TextField
        label="Nome do Dispositivo"
        variant="filled"
        value={formState.name}
        onChange={(e) => updateFormField("name")(e.target.value)}
        required
        fullWidth
      />

      {controls
        .filter((control) => shouldShowControl(control, formState))
        .map((control) => (
          <Box key={control.field}>
            {control.type === "switch" && (
              <>
                <span style={{ fontSize: "14px", fontWeight: 600 }}>
                  {control.label}
                </span>
                <br />
                <Switch
                  checked={formState[control.field] === 1}
                  disabled={control.readOnly}
                  onChange={(event) =>
                    control.field === "masterSwitch"
                      ? handleMasterSwitchChange(control, event.target.checked)
                      : updateFormField(control.field)(
                          event.target.checked ? 1 : 0
                        )
                  }
                />
              </>
            )}

            {control.type === "slider" && (
              <CustomSlider
                label={control.label}
                value={formState[control.field]}
                onChange={updateFormField(control.field)}
                min={control.config?.min || 0}
                max={control.config?.max || 100}
                step={control.config?.step || 1}
                unit={control.config?.unit}
                labelClassName={classes.label}
              />
            )}

            {control.type === "select" && (
              <CustomSelect
                label={control.label}
                value={formState[control.field]}
                onChange={updateFormField(control.field)}
                options={control.options || []}
              />
            )}

            {control.type === "textField" && (
              <TextField
                label={control.label}
                variant="filled"
                type="number"
                value={formState[control.field]}
                disabled={control.readOnly}
                fullWidth
              />
            )}
          </Box>
        ))}
    </Box>
  );
};
