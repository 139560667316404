import axios, { AxiosResponse } from "axios";
import { API_URL } from "../constants";

export interface SmartAccountModel {
  id: number;
  alias: string;
  createdAt: string;
  expiresAt: string;
  deviceCount: number;
  isValid: boolean;
  accountFamily: string;
}

export const getSmartAccount = (
  userId: string
): Promise<AxiosResponse<SmartAccountModel[]>> => {
  return axios.get(`${API_URL}/users/${userId}/smart_accounts`);
};

export const deleteSmartAccount = (
  userId: string,
  accountId: number
): Promise<AxiosResponse<void>> => {
  return axios.delete(`${API_URL}/users/${userId}/smart_accounts/${accountId}`);
};
