import MiniDrawer from "../../Components/CustomMiniDrawer";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import ContentSuccessPaymentPage from "./components/Content";
import { STRIPE_KEY } from "../../constants";

const stripePromise = loadStripe(STRIPE_KEY);

const SuccessPaymentPage: React.FunctionComponent = () => {
  const options = {} as StripeElementsOptions;

  return (
    <MiniDrawer showSideMenu={false} withoutSideMenu={true} bgColor={"#F7F7F7"}>
      <Elements options={options} stripe={stripePromise}>
        <ContentSuccessPaymentPage />
      </Elements>
    </MiniDrawer>
  );
};

export default SuccessPaymentPage;
