import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InfoIcon from "@material-ui/icons/Info";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import {
  fetchAndSetSmartAccount,
  deleteAndSetSmartAccount,
} from "../../../../useCases/getSmartAccount";
import { SmartAccountModel } from "../../../../Services/SmartAccount";
import TokenService from "../../../../Services/TokenService";
import CustomDialogV2 from "../../../../Components/CustomDialogV2";

const useStyles = makeStyles(() => ({
  tableRow: {
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  },
  tableHead: {
    backgroundColor: "#F3FFFA",
    height: "44px",
    "& th": {
      fontSize: "12px",
    },
    color: "#737981",
  },
  tableCellHead: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "#737981",
  },
  containerLoading: {
    margin: "0 auto",
    width: "160px",
    flexDirection: "row",
    alignContent: "center",
    padding: "60px",
    alignItems: "center",
  },
  dialog: {
    minWidth: "500px",
  },
  dialogTitle: {
    "& h2": {
      color: "#035144",
      fontSize: "1.125rem",
      fontWeight: 700,
    },
  },
  headerDialog: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContentText: {
    color: "#3C3C3C",
    fontSize: "1rem",
    fontWeight: 400,
    margin: "24px 0 24px 0",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "24px",
  },
}));

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const DataGridAccount = () => {
  const classes = useStyles();
  const [data, setData] = useState<SmartAccountModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(
    null
  );
  const [selectedAccountName, setSelectedAccountName] = useState<string | null>(
    null
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const userId = TokenService.getUser().userInfo.id;
    fetchAndSetSmartAccount(userId, setData, setLoading, setError);
  }, []);

  const handleDelete = async () => {
    if (selectedAccountId !== null) {
      const userId = TokenService.getUser().userInfo.id;
      await deleteAndSetSmartAccount(
        userId,
        selectedAccountId,
        setData,
        setError,
        setOpen,
        data,
        setSnackbarOpen
      );
    }
  };

  const handleClickOpenDialog = (accountId: number, accountName: string) => {
    setSelectedAccountId(accountId);
    setSelectedAccountName(accountName);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <div className={classes.containerLoading}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div>
        <TableContainer component={Paper}>
          <Table style={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>
                  <div className={classes.tableCellHead}>
                    Apelido da Conta
                    <InfoIcon fontSize="small" />
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.tableCellHead}>
                    Data de vinculação
                    <InfoIcon fontSize="small" />
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.tableCellHead}>
                    Data de expiração <InfoIcon fontSize="small" />
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.tableCellHead}>
                    Dispositivos vinculados <InfoIcon fontSize="small" />
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.tableCellHead}>
                    Status <InfoIcon fontSize="small" />
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className={classes.tableCellHead}>
                    Ações <InfoIcon fontSize="small" />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.id} className={classes.tableRow}>
                  <TableCell component="th" scope="row">
                    {row.alias}
                  </TableCell>
                  <TableCell align="right">{row.createdAt}</TableCell>
                  <TableCell align="right">{row.expiresAt}</TableCell>
                  <TableCell align="right">{row.deviceCount}</TableCell>
                  <TableCell align="right">
                    {row.isValid ? "Válido" : "Inválido"}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => handleClickOpenDialog(row.id, row.alias)}
                    >
                      <DeleteOutlineOutlinedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <CustomDialogV2
        open={open}
        onClose={handleCloseDialog}
        onConfirm={handleDelete}
        title="Exclusão de Contas Vinculadas"
        description={`Tem certeza de que deseja excluir seu vínculo de forma permanente? Caso opte por isso, será necessário refazer o vínculo da sua conta (${selectedAccountName}) caso queira utilizá-la novamente no futuro.`}
        confirmText="Excluir Conta"
        cancelText="Voltar"
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          A sua conta smart {selectedAccountName} foi deletada com sucesso
        </Alert>
      </Snackbar>
    </div>
  );
};
