import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { getHomesById } from "../../../Services/UserService";
import SelectHomePageContext from "./SelectHomePageContext";
import TokenService from "../../../Services/TokenService";
import GlobalContex from "../../../GlobalContext/GlobalContext";
import { HomeUserModel } from "../../../Models/HomeUserModel";
import { useHistory, useLocation } from "react-router-dom";
import { LinkAccountModalRef } from "../../../Components/Modal/LinkAccountModal";

interface Props {
  children: ReactNode;
}

const SelectHomePageContextProvider = ({ children }: Props): JSX.Element => {
  const { showAlert } = useContext(GlobalContex);
  const { search } = useLocation();
  const history = useHistory();

  const linkAccountModalRef = useRef<LinkAccountModalRef>();

  const [homesUser, sethomesUser] = useState<HomeUserModel[]>([]);
  const [isLoadingEnterprise, setIsLoadingEnterprise] = useState<boolean>(true);
  const [linkCode, setLinkCode] = useState<string>("");

  useEffect(() => {
    const urlSearch = new URLSearchParams(search);
    const code = urlSearch.get("code");

    if (code) {
      setLinkCode(code);
      history.replace("/select-home");
    }

    const getDataHomes = async () => {
      try {
        setIsLoadingEnterprise(true);
        const response = await getHomesById(TokenService.getUser().userInfo.id);
        sethomesUser(response.data);
      } catch (error) {
        showAlert("Erro ao carregar os empreendimentos!", "error");
      } finally {
        setIsLoadingEnterprise(false);
      }
    };

    getDataHomes();

    return () => {
      sethomesUser([]);
      setIsLoadingEnterprise(false);
    };
  }, []);

  useEffect(() => {
    if (linkCode && linkAccountModalRef && linkAccountModalRef.current) {
      linkAccountModalRef.current.openModal(linkCode);
    }
  }, [linkCode]);

  return (
    <SelectHomePageContext.Provider
      value={{
        homesUser,
        isLoadingEnterprise,
        linkAccountModalRef,
      }}
    >
      {children}
    </SelectHomePageContext.Provider>
  );
};

export default SelectHomePageContextProvider;
