import {
  makeStyles,
  createStyles,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { useContext } from "react";
import CustomIndicator from "../../../../Components/CustomIndicator";
import EmptyState from "../../../../Components/EmptyState";
import GlobalContex from "../../../../GlobalContext/GlobalContext";
import DeviceModel from "../../../../Models/DeviceModel";
import AllDevicesContext from "../../Context/AllDevicesContext";
import Device from "../Device";
import { ImouDevice } from "../ImouDevice";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: "30px",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      backgroundColor: "#EBEEEE",
      height: "100%",
      overflow: "auto",
    },
  })
);

const DevicesToAssociate: React.FunctionComponent = () => {
  const {
    associatingDevices,
    relocate,
    devicesToShow,
    devices,
    imouDevicesList,
  } = useContext(AllDevicesContext);

  const { home } = useContext(GlobalContex);

  const classes = useStyles();

  return (
    <div
      className={classes.container}
      style={{
        justifyContent: devicesToShow.length === 0 ? "center" : "start",
        borderRadius: relocate ? "0px 0px 25px 25px" : "25px",
      }}
    >
      {home && (
        <CustomIndicator
          maximumNumberOfItens={home.max_device}
          totalItens={devices.length}
          tooltip={
            "Você atingiu o limite de dispositivos do seu plano. Exclua dispositivos ou contrate um novo plano"
          }
          title={"Dispositivos"}
          key={home.max_device}
          icon={"icon_exclamation"}
        />
      )}
      {associatingDevices && <CircularProgress color="secondary" />}
      {devicesToShow.length === 0 && imouDevicesList.length === 0 && (
        <EmptyState
          title="Nenhum dispositivo encontrado"
          subtitle="Verifique se existem dispositivos cadastrados e estão sincronizados através do App Izy Smart se não, contate um administrador."
          image={"assets/imgs/empty-state-imgs/empty-state-img-1.png"}
        />
      )}

      {(devicesToShow.length !== 0 || imouDevicesList.length !== 0) && (
        <Grid
          container
          spacing={3}
          style={{ maxHeight: "100%", overflow: "auto" }}
        >
          {devicesToShow.map((device: DeviceModel, index: number) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              key={"device-item-" + index}
            >
              <Device device={device} />
            </Grid>
          ))}
          {imouDevicesList.map((item) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={2}
                key={"imou-device-item-" + item.deviceId}
              >
                <ImouDevice device={item} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default DevicesToAssociate;
