import {
  Container,
  makeStyles,
  createStyles,
  Grid,
  Button,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { useContext } from "react";
import SelectHomePageContext from "../../Context/SelectHomePageContext";
import { HomeUserModel } from "../../../../Models/HomeUserModel";
import EnterpriseCard from "./EnterpriseCard";
import AddIcon from "@material-ui/icons/Add";
import EmptyState from "../../../../Components/EmptyState";
import history from "../../../../Services/history";
import { LinkAccountModal } from "../../../../Components/Modal/LinkAccountModal";

const useStyles = makeStyles(() =>
  createStyles({
    rootEnterprise: {
      width: "100%",
      backgroundColor: "#E5E5E5",
    },
    containerEnterprises: {
      height: "100%",
      padding: "0px 100px",
    },
    containerHomes: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      paddingTop: "45px",
      overflow: "auto",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "15px",
    },
    header: {
      fontWeight: 700,
      color: "#3E5055",
      fontSize: "28px",
      marginBottom: "auto",
      display: "flex",
      lineHeight: "38px",
    },
    imgHeader: {
      marginRight: "15px",
    },
    containerSubHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
    subHeader: {
      fontWeight: 600,
      color: "#677884",
      fontSize: "15px",
      marginBottom: "auto",
      paddingBottom: "5px",
      lineHeight: "20px",
      marginTop: "10px",
    },
    containerBtnSubHeader: {
      display: "flex",
    },
    buttonManageEnterprise: {
      minWidth: "260px",
      marginRight: "10px",
      textTransform: "none",
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: "20px",
      height: "55px",
    },
    button: {
      textTransform: "none",
      minWidth: "250px",
      height: "55px",
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: "20px",
    },
    contentEnterprises: {
      marginTop: "30px",
      overflow: "auto",
      height: "100%",
      marginBottom: "10px",
    },
  })
);

const ListHomeComponent = (): JSX.Element => {
  const { homesUser, isLoadingEnterprise, linkAccountModalRef } = useContext(
    SelectHomePageContext
  );
  const classes = useStyles();

  return (
    <div className={classes.rootEnterprise}>
      <Container className={classes.containerEnterprises} maxWidth={false}>
        <div className={classes.containerHomes}>
          <div className={classes.titleContainer}>
            <h1 className={classes.header}>
              <img
                src="assets/icons/Caminho 29735.svg"
                width={20}
                className={classes.imgHeader}
              />{" "}
              Selecione o empreendimento
            </h1>
            <div className={classes.containerSubHeader}>
              <h5 className={classes.subHeader}>
                Você pode cadastrar um novo empreendimento e logo receberá a
                autorização através do e-mail cadastrado para acessá-lo no
                sistema
              </h5>
              <div className={classes.containerBtnSubHeader}>
                <Button
                  id={"btn-manage-enterprises"}
                  onClick={() => {
                    history.push("/my-enterprises");
                  }}
                  className={classes.buttonManageEnterprise}
                  variant="outlined"
                  color="secondary"
                >
                  Gerenciar empreendimentos
                </Button>
                <Button
                  id={"btn-create-enterprise"}
                  onClick={() => {
                    history.push("/enterprise/create");
                  }}
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                >
                  <AddIcon /> Novo empreendimento
                </Button>
              </div>
            </div>
          </div>
          <Divider />
          <div className={classes.contentEnterprises}>
            {isLoadingEnterprise ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress color="secondary" size={96} />
              </div>
            ) : (
              <>
                {homesUser.length > 0 ? (
                  <Grid
                    container
                    spacing={3}
                    style={{ padding: "1px", alignItems: "end" }}
                  >
                    {homesUser.map((value: HomeUserModel, index: number) => (
                      <EnterpriseCard
                        homeUser={value}
                        index={index}
                        key={"enterprise-item-" + index}
                      />
                    ))}
                  </Grid>
                ) : (
                  <EmptyState
                    title={"Não há empreendimentos disponíveis"}
                    subtitle={
                      <span>
                        Experimente criar um novo empreendimento clicando no
                        botão{" "}
                        <strong style={{ color: "#00a335" }}>
                          + Novo empreendimento
                        </strong>{" "}
                        ou verifique com um administrador se você tem permissão
                        para acessar essa página
                      </span>
                    }
                    image={"assets/imgs/empty-state-imgs/empty-state-img-2.png"}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Container>
      <LinkAccountModal
        id={"modal-link-imou-account"}
        modalRef={linkAccountModalRef}
      />
    </div>
  );
};

export default ListHomeComponent;
