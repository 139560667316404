import { Button, makeStyles, createStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useImageLoader } from "../../hooks";
import { HeaderLite } from "../../Components/HeaderLite";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      overflow: "hidden",
      backgroundColor: "#EFEFED",
    },
    layout: {
      height: "100vh",
      padding: "0px",
      margin: "0px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    imageContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    image: {
      opacity: 0,
      transition: "opacity 1s ease-in-out",
      "@media (max-width: 1366px) and (max-height: 768px)": {
        height: "400px",
      },
    },
    imageLoaded: {
      opacity: 1,
    },
    title: {
      color: "#40464D",
      fontWeight: 700,
    },
    button: {
      width: "340px",
      fontSize: "14px",
      textTransform: "none",
    },
    boxButtons: {
      display: "flex",
      gap: "10px",
    },
  })
);

const SyncAccount = () => {
  const classes = useStyles();
  const history = useHistory();
  const { imageLoaded, handleImageLoad } = useImageLoader();

  const handleLinkMyAccountLater = () => {
    history.push("/select-home");
  };

  const handleLinkMyAccount = () => {
    history.push("/config-account");
  };

  return (
    <div className={classes.container}>
      <HeaderLite />
      <div className={classes.layout}>
        {imageLoaded && (
          <div style={{ textAlign: "center" }}>
            <h1 className={classes.title}>
              Parece que você não tem nenhuma conta vinculada.
            </h1>
            <p>
              Conecte sua conta MIBO no menu do usuário para acessar e organizar
              seus dispositivos inteligentes.
            </p>
          </div>
        )}
        <div className={classes.imageContainer}>
          <img
            src="assets/imgs/syncAccount.svg"
            alt="Sync Account"
            className={`${classes.image} ${
              imageLoaded ? classes.imageLoaded : ""
            }`}
            onLoad={handleImageLoad}
          />
        </div>
        {imageLoaded && (
          <div className={classes.boxButtons}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              fullWidth
              onClick={handleLinkMyAccountLater}
              className={classes.button}
            >
              Quero vincular minha Conta Smart mais tarde
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              fullWidth
              onClick={handleLinkMyAccount}
              className={classes.button}
            >
              Quero vincular minha Conta Smart agora
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SyncAccount;
