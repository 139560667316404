import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { useContext, useEffect, useState } from "react";
import GlobalContext from "../../GlobalContext/GlobalContext";
import { cacheInfo, purgeCache } from "../../Services/DevicesService";
import { formatDistanceToNow, parseJSON } from "date-fns";
import ptBr from "date-fns/locale/pt-BR";
import { useSyncButtonStyles } from "./SyncButtonStyles";
import SyncOutlinedIcon from "@material-ui/icons/SyncOutlined";
import { SyncButtonProps } from "./SyncButtonTypes";
import AllDevicesContext from "../../Pages/AllDevices/Context/AllDevicesContext";

const SyncButton = ({ onSuccess }: SyncButtonProps) => {
  const { toggleWaitIndicator } = useContext(GlobalContext);
  const { updateRelocateDevices } = useContext(AllDevicesContext);

  const classes = useSyncButtonStyles();

  const [lastUpdateCacheTime, setLastUpdateCacheTime] = useState<
    string | undefined
  >(undefined);

  function forceSync() {
    toggleWaitIndicator("CustomAxctionBar:forceSync", true);
    purgeCache()
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .finally(() => {
        updateRelocateDevices();
        toggleWaitIndicator("CustomAxctionBar:forceSync", false);
      });
  }

  useEffect(() => {
    cacheInfo().then((result) => {
      setLastUpdateCacheTime(result.data);
    });

    return () => {
      setLastUpdateCacheTime(undefined);
    };
  }, []);

  return (
    <Tooltip
      title={
        <>
          Sincronizar com o IzySmart
          <br />
          {lastUpdateCacheTime
            ? `Última sincronização há ${formatDistanceToNow(
                parseJSON(lastUpdateCacheTime),
                { locale: ptBr }
              )}`
            : ""}
        </>
      }
      placement="top"
    >
      <Button
        className={classes.syncButton}
        startIcon={<SyncOutlinedIcon />}
        variant="outlined"
        color="secondary"
        onClick={forceSync}
      >
        Sincronizar
      </Button>
    </Tooltip>
  );
};

export default SyncButton;
