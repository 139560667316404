import { ReactNode, useContext, useEffect } from "react";
import HomePageContext from "./HomePageContext";
import { useState } from "react";
import { listEnvironments } from "../../../Services/Environments";
import EnvironmentModel from "../../../Models/EnvironmentModel";
import GlobalContext from "../../../GlobalContext/GlobalContext";
import { getPlaceNameSelect } from "../../../Services/UserService";
import history from "../../../Services/history";
import {
  getConfigurationsDashboardService,
  updateConfigurationDashboard,
} from "../../../Services/RoomService";
import {
  ConfigurationDashboardModel,
  UpdateConfigurationDashboardPayload,
} from "../../../Models/RoomModel";
import DeviceModel from "../../../Models/DeviceModel";
import {
  getAllAutomations,
  setAutomationListFavorited,
} from "../../../Services/AutomationService";
import { useLocation } from "react-router-dom";
import { Automations } from "../../../Models/Automation";

interface Props {
  children: ReactNode;
}

const HomePageContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { children } = props;

  const { room, setRoomIdR, setPathnameUrl, showAlert } =
    useContext(GlobalContext);

  const [environments, setEnvironments] = useState<EnvironmentModel[]>([]);
  const [openAutomationsFavoritedDrawer, setOpenAutomationsFavoritedDrawer] =
    useState<boolean>(false);
  const [openShortcutsDrawer, setOpenShortcutsDrawer] =
    useState<boolean>(false);
  const [statusAutomationsShortcut, setStatusAutomationsShortcut] =
    useState<boolean>(false);
  const [statusDeviceInPlaceShortcut, setStatusDeviceInPlaceShortcut] =
    useState<boolean>(false);
  const [statusDevicesShortcut, setStatusDevicesShortcut] =
    useState<boolean>(false);
  const [loadingDashboardConfiguration, setLoadingDashboardConfiguration] =
    useState<boolean>(true);
  const [dashboardConfiguration, setDashboardConfiguration] =
    useState<ConfigurationDashboardModel>({} as ConfigurationDashboardModel);
  const [dashboardConfigurationDevices, setDashboardConfigurationDevices] =
    useState<DeviceModel[]>([]);
  const [scenes, setScenes] = useState<Automations[]>([]);
  const [isLoadingScenes, setIsLoadingScenes] = useState<boolean>(false);
  const [filterValueAutomationList, setFilterValueAutomationList] =
    useState<string>("");
  const [automationsIdsToSave, setAutomationsIdsToSave] = useState<string[]>(
    []
  );
  const [favoriteAutomationsIds, setFavoriteAutomationsIds] = useState<
    string[]
  >([]);
  const [dashboardTabValue, setDashboardTabValue] = useState<number>(0);

  const dataRedirect = useLocation<{ dashboardTabValue: number }>();

  useEffect(() => {
    const syncBtnCheck = localStorage.getItem("syncBtnCheck");
    if (syncBtnCheck) {
      const selectedRoom = localStorage.getItem("roomSelected");
      const selectedPlaceName = getPlaceNameSelect();
      if (selectedRoom && selectedRoom !== "" && selectedPlaceName.length > 0) {
        setRoomIdR(JSON.parse(selectedRoom));
        localStorage.removeItem("roomSelected");
      } else {
        history.push("/manage-place");
      }
      localStorage.removeItem("syncBtnCheck");
    } else {
      if (getPlaceNameSelect().length === 0 || room.room_id === undefined) {
        history.push("/select-home");
      }

      if (room !== undefined) {
        setRoomIdR(room);
      }

      if (dataRedirect.state && dataRedirect.state.dashboardTabValue) {
        setDashboardTabValue(dataRedirect.state.dashboardTabValue);
      }
    }
    setPathnameUrl(window.location.pathname);

    getAllScenes();

    return () => {
      setEnvironments([]);
      setOpenAutomationsFavoritedDrawer(false);
      setOpenShortcutsDrawer(false);
      setStatusAutomationsShortcut(false);
      setStatusDeviceInPlaceShortcut(false);
      setStatusDevicesShortcut(false);
      setLoadingDashboardConfiguration(false);
      setDashboardConfiguration({} as ConfigurationDashboardModel);
      setDashboardConfigurationDevices([]);
      setScenes([]);
      setIsLoadingScenes(false);
    };
  }, []);

  const getEnvironments = async (id: string) => {
    try {
      const result = await listEnvironments(id);
      setEnvironments([...result.data]);
    } catch (error) {
      showAlert("Erro na requisição.", "error");
    }
  };

  const updateRelocateDevices = () => {
    const r = room;
    getEnvironments(r.room_id);
    setRoomIdR(r);
  };

  const toggleAutomationsFavoritedDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpenAutomationsFavoritedDrawer(open);
    };

  const toggleShortcutsDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpenShortcutsDrawer(open);
    };

  useEffect(() => {
    if (!room || !room.room_id) {
      return;
    }

    try {
      getConfigurationsDashboardService(room.room_id).then((response) => {
        const { data, status } = response;
        if (status === 200) {
          setDashboardConfiguration(data);
          setDashboardConfigurationDevices(data.favorite_devices);
          setStatusAutomationsShortcut(data.display_favorite_automation);
          setStatusDeviceInPlaceShortcut(
            data.display_all_device_and_environment
          );
          setStatusDevicesShortcut(data.display_favorite_device);
          setLoadingDashboardConfiguration(false);
        } else {
          showAlert(
            "Houve algum problema aocarregar as informações",
            "warning"
          );
        }
      });
    } catch (error: any) {
      console.log("error", error);
    }
  }, [room]);

  const changeStatusAutomationsShortcut = async (
    statusAutomationsShortcut: boolean
  ) => {
    try {
      const payload: UpdateConfigurationDashboardPayload = {
        display_favorite_automation: !statusAutomationsShortcut,
        display_favorite_device: statusDeviceInPlaceShortcut,
        display_all_device_and_environment: statusDevicesShortcut,
      };

      const { status } = await updateConfigurationDashboard(
        room.room_id,
        payload
      );

      if (status === 200) {
        setStatusAutomationsShortcut(!statusAutomationsShortcut);
        showAlert("Configuração salva", "success");
      } else {
        showAlert("Algo deu errado", "warning");
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    }
  };

  const changeStatusDeviceInPlaceShortcut = async (
    statusDeviceInPlaceShortcut: boolean
  ) => {
    try {
      const payload: UpdateConfigurationDashboardPayload = {
        display_favorite_automation: statusAutomationsShortcut,
        display_favorite_device: statusDevicesShortcut,
        display_all_device_and_environment: !statusDeviceInPlaceShortcut,
      };
      const { status } = await updateConfigurationDashboard(
        room.room_id,
        payload
      );

      if (status === 200) {
        setStatusDeviceInPlaceShortcut(!statusDeviceInPlaceShortcut);
        showAlert("Configuração salva", "success");
      } else {
        showAlert("Algo deu errado", "warning");
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    }
  };

  const changeStatusDevicesShortcut = async (
    statusDevicesShortcut: boolean
  ) => {
    try {
      const payload: UpdateConfigurationDashboardPayload = {
        display_favorite_automation: statusAutomationsShortcut,
        display_favorite_device: !statusDevicesShortcut,
        display_all_device_and_environment: statusDeviceInPlaceShortcut,
      };

      const { status } = await updateConfigurationDashboard(
        room.room_id,
        payload
      );
      if (status === 200) {
        setStatusDevicesShortcut(!statusDevicesShortcut);
        showAlert("Configuração salva", "success");
      } else {
        showAlert("Algo deu errado", "warning");
      }
    } catch (error: any) {
      showAlert(error.message, "error");
    }
  };

  const getAllScenes = async () => {
    setIsLoadingScenes(true);
    try {
      const response = await getAllAutomations();
      if (response.status === 200) {
        setScenes(response.data.filter((scene) => scene.scene_id));
      }
    } catch (error) {
      showAlert("Erro ao carregar automaçoes", "warning");
    } finally {
      setIsLoadingScenes(false);
    }
  };

  const saveAutomationList = async () => {
    setIsLoadingScenes(true);
    const automationsToSave = scenes.filter((s) => {
      if (s.scene_id && automationsIdsToSave.includes(s.scene_id)) {
        return s;
      }
      return false;
    });
    try {
      const response = await setAutomationListFavorited(
        room.room_id,
        automationsToSave
      );
      const update = await getConfigurationsDashboardService(room.room_id);

      if (response.status === 200 && update.status === 200) {
        setDashboardConfiguration(update.data);
        showAlert("As automações foram salvar com sucesso", "success");
      } else {
        showAlert("Houve um problema ao salvar as automações!", "warning");
      }
    } catch (error) {
      showAlert("error", "error");
    } finally {
      setIsLoadingScenes(false);
      setOpenAutomationsFavoritedDrawer(false);
    }
  };

  useEffect(() => {
    if (openAutomationsFavoritedDrawer) {
      setAutomationsIdsToSave(favoriteAutomationsIds);
    }
  }, [openAutomationsFavoritedDrawer, favoriteAutomationsIds]);

  return (
    <HomePageContext.Provider
      value={{
        environments,
        getEnvironments,
        updateRelocateDevices,
        openShortcutsDrawer,
        setOpenShortcutsDrawer,
        toggleShortcutsDrawer,
        changeStatusAutomationsShortcut,
        changeStatusDeviceInPlaceShortcut,
        changeStatusDevicesShortcut,
        dashboardConfiguration,
        loadingDashboardConfiguration,
        statusAutomationsShortcut,
        statusDeviceInPlaceShortcut,
        statusDevicesShortcut,
        dashboardConfigurationDevices,
        setDashboardConfigurationDevices,
        openAutomationsFavoritedDrawer,
        setOpenAutomationsFavoritedDrawer,
        toggleAutomationsFavoritedDrawer,
        scenes,
        isLoadingScenes,
        filterValueAutomationList,
        setFilterValueAutomationList,
        automationsIdsToSave,
        setAutomationsIdsToSave,
        saveAutomationList,
        setFavoriteAutomationsIds,
        favoriteAutomationsIds,
        dashboardTabValue,
        setDashboardTabValue,
      }}
    >
      <div
        style={{
          backgroundColor: "#FFF",
          width: "100%",
          overflow: "auto",
          height: "100%",
        }}
      >
        {children}
      </div>
    </HomePageContext.Provider>
  );
};

export default HomePageContextProvider;
