import { AxiosResponse } from "axios";
import { IImouApiResponse } from "../Models/ImouApiResponseModel";
import { IImouListDeviceResponse } from "../Models/ImouDevicesModel";
import api from "./Api";
import TokenService from "./TokenService";

interface IGetImouDeviceListParams {
  size: number;
  page: number;
}

interface IGetImouDeviceProperties {
  deviceId: string;
  alias?: string;
  productId?: string;
  properties?: string[];
}

interface IUpdateImouDeviceProperties {
  deviceId: string;
  alias?: string;
  productId?: string;
  properties?: { [key: string]: number | string | object };
}

interface IUpdateImouDeviceParams {
  deviceId: string;
  newName: string;
  alias?: string;
}

export const getImouDevicesList = ({
  size,
  page,
}: IGetImouDeviceListParams): Promise<
  AxiosResponse<IImouApiResponse<IImouListDeviceResponse>>
> => {
  return api.get(`imou/users/${TokenService.getUser().userInfo.id}/devices`, {
    params: {
      size: size,
      page: page,
    },
  });
};

export const getImouDeviceProperties = ({
  deviceId,
  alias,
  productId,
  properties = [],
}: IGetImouDeviceProperties) => {
  return api.get(
    `/imou/users/${
      TokenService.getUser().userInfo.id
    }_${alias}/devices/${deviceId}/${productId}`,
    {
      params: {
        properties,
      },
      paramsSerializer: (params) => {
        return Object.keys(params)
          .map((key) =>
            Array.isArray(params[key])
              ? params[key]
                  .map((value: string) => `${key}=${encodeURIComponent(value)}`)
                  .join("&")
              : `${key}=${encodeURIComponent(params[key])}`
          )
          .join("&");
      },
    }
  );
};

export const updateImouDevice = ({
  deviceId,
  newName,
  alias,
}: IUpdateImouDeviceParams) => {
  return api.put(
    `/imou/users/${
      TokenService.getUser().userInfo.id
    }_${alias}/devices/${deviceId}?name=${newName}`
  );
};

export const updateImouPropsDevice = ({
  deviceId,
  alias,
  productId,
  properties,
}: IUpdateImouDeviceProperties) => {
  return api.post(
    `/imou/users/${
      TokenService.getUser().userInfo.id
    }_${alias}/devices/${deviceId}/${productId}`,
    {
      properties: properties,
    }
  );
};
