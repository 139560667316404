import GlobalContextProvider from "./GlobalContext/GlobalContextProvider";
import CustomSnackbar from "./Components/CustomSnackbar";
import { Routes } from "./Routes";
import CustomDrawerApplication from "./Components/CustomDrawerApplication";
import ToggleBottomMenu from "./Components/ToggleBottomMenu";
import { isRunningLocalEnvironment } from "./constants";

function App(): JSX.Element {
  return (
    <GlobalContextProvider>
      {isRunningLocalEnvironment && <ToggleBottomMenu />}
      <Routes />
      <CustomDrawerApplication />
      <CustomSnackbar />
    </GlobalContextProvider>
  );
}

export default App;
