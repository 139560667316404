import { Redirect, useLocation } from "react-router-dom";

const AuthCode = () => {
  const { search } = useLocation();

  const urlSearch = new URLSearchParams(search);
  const code = urlSearch.get("code");

  return (
    <Redirect
      to={{
        pathname: "/select-home",
        search: `?code=${code}`,
      }}
    />
  );
};

export default AuthCode;
