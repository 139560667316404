import { useState } from "react";

export const useImageLoader = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return { imageLoaded, handleImageLoad };
};
