import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  hoverArea: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "200px",
    height: "200px",
    zIndex: 1000,
  },
  toggleButton: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    padding: "10px",
    backgroundColor: "cornflowerblue",
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    cursor: "pointer",
    opacity: 0,
    transition: "opacity 0.3s",
  },
  toggleButtonVisible: {
    opacity: 1,
  },
  popover: {
    padding: theme.spacing(2),
  },
}));

const ToggleBottomMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [visible, setVisible] = useState(false);
  const [apiFake, setApiFake] = useState<boolean>(() => {
    return localStorage.getItem("apiFake") === "true";
  });

  const handleMouseEnter = () => {
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setApiFake(isChecked);
    localStorage.setItem("apiFake", isChecked.toString());
    window.location.reload();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div
      className={classes.hoverArea}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        title="only dev mode"
        className={`${classes.toggleButton} ${
          visible ? classes.toggleButtonVisible : ""
        }`}
        onClick={handleClick}
      >
        +
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div className={classes.popover}>
          <Typography variant="body1">fake backend</Typography>
          <Switch
            checked={apiFake}
            onChange={handleToggleChange}
            color="secondary"
          />
        </div>
      </Popover>
    </div>
  );
};

export default ToggleBottomMenu;
