import { Button, createStyles, Link, makeStyles } from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import EnterpriseContext from "../../context/EnterpriseContext";
import Grid from "@material-ui/core/Grid";
import Box from "./components/Box";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import CheckoutForm from "./components/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { postPlanForPayment } from "../../../../Services/PlansService";
import { planPriceInterval } from "../../../../Models/Plans";
import { toREAL } from "../../../../Utils/CurrencyUtils";
import EnterpriseDataView from "./components/EnterpriseDataView";
import ItemIcon from "../ItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import StripeTerms from "../../../../Components/StripeTermsDialog";
import { STRIPE_KEY } from "../../../../constants";

const stripePromise = loadStripe(STRIPE_KEY);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      paddingTop: 10,
    },
    resume: {
      display: "flex",

      "& .resume__item": {
        fontSize: 15,
        display: "flex",
        alignItems: "center",
      },

      "& .ItemIcon--root": {
        marginRight: 12,

        "& .MuiSvgIcon-root": {
          width: "2em",
          height: "2em",
        },
      },

      "& .resume__payment": {
        flexGrow: 2,

        "& .payment_method > p, & .payment_total > p": {
          display: "flex",
          justifyContent: "space-between",
        },
      },

      "& .resume__hint": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "33%",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "20px",
        padding: "12px",
      },

      "& .resume__divider": {
        width: 1,
        display: "flex",
        borderLeft: "1px solid #D1DAE0",
        margin: "0 30px",
      },
    },
    imageIcon: {
      backgroundColor: "#009B8B",
      color: "#FFF",
      width: 66,
      height: 66,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 10,
    },
    total: {
      color: "#D72735",
      fontWeight: 700,
      fontSize: "21px",
    },
    horizontalDivider: {
      border: 0,
      borderTop: "1px solid",
      width: "calc(100% + 48px)",
      margin: "16px -24px",
      color: "#EBEEEE",
    },
    button: {
      textTransform: "none",
      fontSize: "15px",
      fontWeight: "bold",
      padding: "12px",
      marginTop: "10px",
    },
  })
);

const mesMap: { [value in planPriceInterval]: string } = {
  day: "Diário",
  month: "Mensal",
  year: "Anual",
};

const Step3: React.FunctionComponent = () => {
  const classes = useStyles();

  const { enterpriseId, registerEnterprise, selectedPlan, priceSelected } =
    useContext(EnterpriseContext);

  const [clientSecret, setClientSecret] = useState<string>("");
  const [checkeRulesAndTerms, setCheckeRulesAndTerms] =
    useState<boolean>(false);
  const [openModalRulesAndTerms, setOpenModalRulesAndTerms] =
    useState<boolean>(false);

  const data = registerEnterprise;

  useEffect(() => {
    return () => {
      setClientSecret("");
    };
  }, []);

  useEffect(() => {
    if (priceSelected.id !== "") {
      postPlanForPayment(enterpriseId, priceSelected!.id).then((response) => {
        setClientSecret(response.data.clientSecret);
      });
    }
  }, [priceSelected]);

  const options = {
    clientSecret,
    appearance: {
      theme: "stripe",
    },
  } as StripeElementsOptions;

  const checkoutFormRef = useRef<{ submit: () => void } | null>(null);

  return (
    <>
      <Grid item xs={8}>
        <Box stepNumber={1} title="Informações fiscais">
          <div style={{ marginLeft: 42 }}>
            <EnterpriseDataView data={data} />
          </div>
        </Box>
        <Box stepNumber={2} title="Método de pagamento">
          <div style={{ marginLeft: 42 }}>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm
                  ref={checkoutFormRef}
                  priceInterval={priceSelected!.interval}
                />
              </Elements>
            )}
          </div>
        </Box>

        <Box stepNumber={3} title="Resumo da compra">
          <div className={classes.resume}>
            <ItemIcon color={selectedPlan.color_plan} />
            <div className={"resume__payment"}>
              <p>
                <strong>
                  {selectedPlan.name} {mesMap[priceSelected!.interval]}
                </strong>
              </p>
              <div className={"payment_total"}>
                <p>
                  Valor da compra{" "}
                  <span className={classes.total}>
                    {toREAL(priceSelected?.price)}
                  </span>
                </p>
              </div>
            </div>
            <div className={"resume__divider"}></div>
            <div className={"resume__hint"}>
              Ao finalizar o pedido, você receberá uma mensagem para o e-mail
              informado no campo de “Informações Pessoais”.
            </div>
          </div>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box hideHeader>
          <div className={classes.resume} style={{ flexDirection: "column" }}>
            <div className={"resume__item"}>
              <ItemIcon color={selectedPlan.color_plan} />
              <strong>
                {selectedPlan.name} {mesMap[priceSelected!.interval]}
              </strong>
            </div>
            <hr className={classes.horizontalDivider} />
            <div className={"resume__payment"}>
              <div className={"payment_total"}>
                <p>
                  Valor da compra{" "}
                  <span className={classes.total}>
                    {toREAL(priceSelected?.price)}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Box>
        <div>
          <p className={"m-0"}>
            <strong>RENOVAÇÃO AUTOMÁTICA:</strong>
          </p>
          <p>
            Sua assinatura será renovada automaticamente de forma{" "}
            {mesMap[priceSelected!.interval].toLowerCase()} com um pagamento de{" "}
            <strong>{toREAL(priceSelected?.price)}</strong>. Você pode cancelar
            sua assinatura a qualquer momento na seção{" "}
            <strong>Meu Perfil</strong>.
          </p>
          <Checkbox
            checked={checkeRulesAndTerms}
            onChange={(event) => setCheckeRulesAndTerms(event.target.checked)}
            inputProps={{ "aria-label": "primary checkbox" }}
            id={"checkbox-term"}
          />
          <span>
            Eu li e aceito os{" "}
            <strong>
              <Link
                href="#"
                color="secondary"
                onClick={(e) => {
                  setOpenModalRulesAndTerms(true);
                  e.preventDefault();
                }}
              >
                termos de uso
              </Link>{" "}
            </strong>
            do stripe
          </span>
        </div>

        <Button
          id="btn-finish"
          color="secondary"
          disabled={!checkeRulesAndTerms}
          variant="contained"
          fullWidth={true}
          className={classes.button}
          onClick={() => checkoutFormRef?.current?.submit()}
        >
          Efetuar compra
        </Button>
      </Grid>

      <StripeTerms
        open={openModalRulesAndTerms}
        onClose={() => setOpenModalRulesAndTerms(false)}
      />
    </>
  );
};

export default Step3;
