import { ReactNode, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Avatar, MenuItem } from "@material-ui/core";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { getCurrentUser, logout } from "../../Services/AuthService";
import Breadcrumb from "../Breadcrumb";
import GlobalContex from "../../GlobalContext/GlobalContext";
import Typography from "@material-ui/core/Typography";
import LinkBread from "@material-ui/core/Link";
import { BreadcrumbUrl } from "../../Models/BreadcrumbType";
import CustomDrawerNotification from "../../Components/CustomDrawerNotification";
import CustomDrawerSideMenu, { drawerWidth } from "../CustomDrawerSideMenu";
import { Link } from "react-router-dom";
import CustomModalDowngradePlan from "../CustomModalDowngradePlan";
import { UserLogin } from "../../Models/UserLogin";
import MenuButtom from "./MenuButtom";
import { AUTH_IMOU_CLIENT_ID } from "../../constants";
import { useHistory } from "react-router-dom";
import {
  Settings,
  ExitToApp,
  NotificationsNone,
  Sync,
} from "@material-ui/icons";

interface Props {
  children?: ReactNode;
  showSideMenu?: boolean;
  breadcrumb?: BreadcrumbUrl[] | null;
  withoutSideMenu?: boolean;
  bgColor?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: "75px",
  },
  appBarShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      width: `calc(100vw - ${drawerWidth}px)`,
    },
  },
  withSideMenu: {
    width: `calc(100% - ${theme.spacing(9) + 1}px)`,
  },
  noSideMenu: {
    width: "100%",
  },
  content: {
    height: "calc(100vh - 75px)",
    marginTop: "75px",
    flex: 1,
    backgroundColor: "#FFF",
  },
  toolbarClass: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "space-between",
  },
  avatarMenu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: "black",
    flexGrow: 1,
  },
  userName: {
    fontSize: "15px",
    lineHeight: "20px",
    color: "#3E5055",
    textTransform: "capitalize",
    fontWeight: 600,
  },
  breadcrumbGeneral: {
    "& .makeStyles-breadcrumb-29": {
      padding: "1.5rem 2rem",
      zIndex: "99",
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  redDotNotifications: {
    position: "absolute",
    width: "8px",
    height: "8px",
    backgroundColor: "#D72735",
    borderRadius: "25px",
    top: "5px",
    right: "5px",
  },
  avatarMenuItem: {
    color: "#3e5055",
    cursor: "pointer",
  },
}));

const MiniDrawer: React.FunctionComponent<Props> = (props: Props) => {
  const { children, showSideMenu = true, breadcrumb } = props;
  const history = useHistory();
  const {
    setIsLogged,
    isLogged,
    setPlaceSelected,
    updateDevicesList,
    setPlaces,
    setRooms,
    openSideBar,
    waitingActions,
    toggleNotificationDrawer,
    newNotificationAlert,
  } = useContext(GlobalContex);

  const classes = useStyles();
  const [checkSideMenu, setCheckSideMenu] = useState(false);
  const [user] = useState<UserLogin>(getCurrentUser());

  const logoff = () => {
    updateDevicesList([]);
    setPlaces([]);
    setRooms([]);
    logout();
    setIsLogged(!isLogged);
    setPlaceSelected({
      place_id: "0",
      name: "",
      rooms: [],
      home_id: "",
    });
  };

  const checkProps = () => {
    if (props.withoutSideMenu) {
      setCheckSideMenu(false);
    } else {
      setCheckSideMenu(true);
    }
  };

  const handleClickLinkImouAccount = () => {
    window.location.href = `https://auth.imoulife.com/login-apply?clientId=${AUTH_IMOU_CLIENT_ID}&loginType=apply&client=apply`;
  };

  const handleConfigAccountClick = () => {
    history.push("/config-account");
  };

  useEffect(() => {
    checkProps();
  }, [props.withoutSideMenu]);

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.noSideMenu]: !checkSideMenu,
          [classes.withSideMenu]: !checkSideMenu,
          [classes.appBarShift]: openSideBar,
        })}
      >
        <Toolbar className={classes.toolbarClass}>
          {breadcrumb ? (
            <Breadcrumb className={classes.breadcrumbGeneral}>
              {breadcrumb?.map((item, index) => {
                if (index + 1 === breadcrumb.length) {
                  return (
                    <Typography color="textPrimary" key={index}>
                      {item.name}
                    </Typography>
                  );
                } else {
                  return (
                    <LinkBread key={index} color="inherit" href={item.to}>
                      {item.name}
                    </LinkBread>
                  );
                }
              })}
            </Breadcrumb>
          ) : (
            <Link to={"/select-home"}>
              <img src="assets/imgs/intelbras-logo.png" alt="" height={25} />
            </Link>
          )}

          <div className={classes.avatarMenu}>
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <>
                  <span className={classes.userName}>
                    {" "}
                    {user?.userInfo?.name}{" "}
                  </span>
                  <Avatar
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    src="assets/imgs/avatar.png"
                    {...bindTrigger(popupState)}
                  />
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem
                      aria-label="vincular conta mibo"
                      className={classes.avatarMenuItem}
                      onClick={handleClickLinkImouAccount}
                    >
                      <Sync /> Vincular Conta Mibo
                    </MenuItem>
                    <MenuItem
                      aria-label="perfil e configurações"
                      className={classes.avatarMenuItem}
                      onClick={handleConfigAccountClick}
                    >
                      <Settings /> Perfil e Configurações
                    </MenuItem>

                    <MenuItem
                      aria-label="sair"
                      className={classes.avatarMenuItem}
                      onClick={logoff}
                    >
                      <ExitToApp /> Sair
                    </MenuItem>
                  </Popover>
                </>
              )}
            </PopupState>
            {showSideMenu && (
              <Avatar
                style={{
                  backgroundColor: "#EBEEEE",
                  marginLeft: "15px",
                }}
                onClick={() => {
                  toggleNotificationDrawer(true);
                }}
              >
                <IconButton>
                  <NotificationsNone htmlColor="#8B979F" />
                </IconButton>
                {(newNotificationAlert ||
                  localStorage.getItem("newNotification") === "true") && (
                  <span className={classes.redDotNotifications}></span>
                )}
              </Avatar>
            )}
            <MenuButtom />
          </div>
        </Toolbar>
      </AppBar>

      {showSideMenu && <CustomDrawerSideMenu />}

      <main className={classes.content}>
        {waitingActions.length > 0 && (
          <Backdrop
            className={classes.backdrop}
            open={waitingActions.length > 0}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {
          <div
            style={{
              display: waitingActions.length > 0 ? "none" : "flex",
              backgroundColor: props.bgColor ? props.bgColor : "#FFF",
              height: "100%",
              overflow: "auto",
            }}
          >
            {children}
          </div>
        }
      </main>
      <CustomDrawerNotification />
      {showSideMenu && <CustomModalDowngradePlan />}
    </div>
  );
};

export default MiniDrawer;
