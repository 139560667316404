export interface ControlConfig {
  type: "slider" | "select" | "switch" | "textField";
  label: string;
  field: string;
  propertyId: string;
  defaultValue?: any;
  readOnly?: boolean;
  options?: Array<{ value: any; label: string }>;
  config?: {
    min?: number;
    max?: number;
    step?: number;
    unit?: string;
  };
  showIf?: {
    field: string;
    value: any;
  };
  jsonProperty?: {
    property: string;
    field: string;
    isArray?: boolean;
  };
  controlsChildren?: string[];
}

export const deviceModelControls: Record<string, ControlConfig[]> = {
  "IOT-MB5C1-IB": [
    {
      type: "switch",
      label: "Liga/Desliga",
      field: "masterSwitch",
      propertyId: "11900",
    },
    {
      type: "select",
      label: "Modo Atual da Lâmpada",
      field: "lightMode",
      propertyId: "1010",
      options: [
        { value: 0, label: "Luz Branca" },
        { value: 1, label: "Luz Colorida" },
        { value: 2, label: "Cena" },
        { value: 3, label: "Ritmo" },
      ],
    },
    {
      type: "slider",
      label: "Brilho da luz Branca",
      field: "brightness",
      propertyId: "1002",
      showIf: { field: "lightMode", value: 0 },
      config: { min: 1, max: 100, step: 1, unit: "%" },
    },
    {
      type: "slider",
      label: "Temperatura da luz Branca",
      field: "temperature",
      propertyId: "1003",
      showIf: { field: "lightMode", value: 0 },
      config: { min: 2700, max: 6500, step: 100, unit: "K" },
    },
    {
      type: "slider",
      label: "Tom (Hue) da luz colorida",
      field: "colorHue",
      propertyId: "1004",
      showIf: { field: "lightMode", value: 1 },
      config: { min: 0, max: 360, step: 1, unit: "º" },
    },
    {
      type: "slider",
      label: "Brilho da luz colorida",
      field: "lightColorValue",
      propertyId: "1006",
      showIf: { field: "lightMode", value: 1 },
      config: { min: 0, max: 100, step: 1, unit: "%" },
    },
    {
      type: "slider",
      label: "Contraste da luz colorida",
      field: "colorSaturation",
      propertyId: "1005",
      showIf: { field: "lightMode", value: 1 },
      config: { min: 0, max: 100, step: 1, unit: "%" },
    },
    {
      type: "select",
      label: "Cena de iluminação predefinida",
      field: "lightScene",
      propertyId: "1007",
      showIf: { field: "lightMode", value: 2 },
      options: [
        { value: 0, label: "Dia" },
        { value: 1, label: "Noite" },
        { value: 2, label: "Filme" },
        { value: 3, label: "Descanso" },
        { value: 4, label: "Romance" },
        { value: 5, label: "Festa" },
        { value: 6, label: "Natal" },
        { value: 7, label: "Halloween" },
        { value: 8, label: "Acordar" },
      ],
    },
    {
      type: "slider",
      label: "Velocidade",
      field: "controlSpeed",
      propertyId: "1009",
      showIf: { field: "lightMode", value: 2 },
      jsonProperty: {
        property: "1009",
        field: "speed",
      },
      config: { min: 100, max: 1000, step: 1, unit: "" },
    },
    {
      type: "select",
      label: "Modo",
      field: "controlMode",
      propertyId: "1009",
      showIf: { field: "lightMode", value: 2 },
      jsonProperty: {
        property: "1009",
        field: "mode",
      },
      options: [
        { value: 0, label: "Invariável" },
        { value: 1, label: "Tremer" },
        { value: 2, label: "Pausa" },
        { value: 3, label: "Iluminar gradualmente" },
        { value: 4, label: "Gradualmente escurecido" },
      ],
    },
    {
      type: "select",
      label: "Cor da Cena",
      field: "sceneColor",
      propertyId: "",
      showIf: { field: "lightMode", value: 2 },
      options: [
        { value: 0, label: "Branco" },
        { value: 1, label: "Colorido" },
      ],
    },
    //Branco em cor de cena
    {
      type: "slider",
      label: "Brilho",
      field: "brightnessScene",
      propertyId: "1008",
      showIf: { field: "sceneColor", value: 0 },
      jsonProperty: {
        property: "1008",
        field: "bright",
        isArray: true,
      },
      config: { min: 1, max: 100, step: 1, unit: "%" },
    },
    {
      type: "slider",
      label: "Calor",
      field: "temperature",
      propertyId: "1008",
      showIf: { field: "sceneColor", value: 0 },
      jsonProperty: {
        property: "1008",
        field: "temp",
        isArray: true,
      },
      config: { min: 2700, max: 6500, step: 100, unit: "K" },
    },
    // cor da cena
    {
      type: "slider",
      label: "Cor",
      field: "h",
      propertyId: "1008",
      showIf: { field: "sceneColor", value: 1 },
      jsonProperty: {
        property: "1008",
        field: "h",
        isArray: true,
      },
      config: { min: 0, max: 360, step: 1, unit: "º" },
    },
    {
      type: "slider",
      label: "Brilho",
      field: "s",
      propertyId: "1008",
      jsonProperty: {
        property: "1008",
        field: "s",
        isArray: true,
      },
      showIf: { field: "sceneColor", value: 1 },
      config: { min: 0, max: 100, step: 1, unit: "%" },
    },
    {
      type: "slider",
      label: "Contraste",
      field: "v",
      propertyId: "1008",
      showIf: { field: "sceneColor", value: 1 },
      jsonProperty: {
        property: "1008",
        field: "v",
        isArray: true,
      },
      config: { min: 0, max: 100, step: 1, unit: "%" },
    },
    {
      type: "select",
      label: "Estado do Relé",
      field: "rstState",
      propertyId: "129000",
      options: [
        { value: 0, label: "Ligado" },
        { value: 1, label: "Desligado" },
        { value: 2, label: "Retomar Último Estado" },
      ],
    },
  ],
  "IOT-MW2C1-IB": [
    {
      type: "switch",
      label: "Liga/Desliga",
      field: "masterSwitch",
      propertyId: "126900",
      controlsChildren: ["switch1", "switch2"],
    },
    {
      type: "select",
      label: "Modo de Controle",
      field: "modeControl",
      defaultValue: 0,
      propertyId: "",
      options: [
        { value: 0, label: "Interruptores" },
        { value: 1, label: "Demais Propriedades" },
      ],
    },
    {
      type: "switch",
      label: "Tecla 1",
      field: "switch1",
      propertyId: "11900",
      showIf: { field: "modeControl", value: 0 },
    },
    {
      type: "switch",
      label: "Tecla 2",
      field: "switch2",
      propertyId: "127000",
      showIf: { field: "modeControl", value: 0 },
    },
    {
      type: "select",
      label: "Estado do Relé",
      field: "rstState",
      propertyId: "129000",
      options: [
        { value: 0, label: "Ligado" },
        { value: 1, label: "Desligado" },
        { value: 2, label: "Retomar Último Estado" },
      ],
      showIf: { field: "modeControl", value: 1 },
    },
    {
      type: "select",
      label: "Led Status",
      field: "ledStatus",
      propertyId: "131500",
      options: [
        { value: 0, label: "Led Indicador Desligado" },
        { value: 1, label: "Led Ligado" },
        { value: 2, label: "Indicação Ligar/Desligado" },
        { value: 3, label: "Indicação da localização do interruptor" },
      ],
    },
  ],
  "IOT-MW3C1-IB": [
    {
      type: "switch",
      label: "Liga/Desliga",
      field: "masterSwitch",
      controlsChildren: ["switch1", "switch2", "switch3"],
      propertyId: "126900",
    },
    {
      type: "select",
      label: "Modo de Controle",
      field: "modeControl",
      defaultValue: 0,
      propertyId: "",
      options: [
        { value: 0, label: "Interruptores" },
        { value: 1, label: "Demais Propriedades" },
      ],
    },
    {
      type: "switch",
      label: "Tecla 1",
      field: "switch1",
      propertyId: "11900",
      showIf: { field: "modeControl", value: 0 },
    },
    {
      type: "switch",
      label: "Tecla 2",
      field: "switch2",
      propertyId: "127000",
      showIf: { field: "modeControl", value: 0 },
    },
    {
      type: "switch",
      label: "Tecla 3",
      field: "switch3",
      propertyId: "127100",
      showIf: { field: "modeControl", value: 0 },
    },
    {
      type: "select",
      label: "Estado do Relé",
      field: "rstState",
      propertyId: "129000",
      options: [
        { value: 0, label: "Ligado" },
        { value: 1, label: "Desligado" },
        { value: 2, label: "Retomar Último Estado" },
      ],
      showIf: { field: "modeControl", value: 1 },
    },
  ],
};
